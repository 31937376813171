@use "../mixin/index" as *;

.mt_0 {
  margin-top: 0;
}
.mb_0 {
  margin-bottom: 0;
}

.pt_0 {
  padding-top: 0;
}

.tabonly {
  display: block;
  @media only screen and (min-width: 769px) {
    display: block;
  }
  @media only screen and (min-width: 1024px) {
    display: none;
  }
}
.pconly {
  display: none;
  @media only screen and (min-width: 1024px) {
    display: block;
  }
}

.homepagepage .inpage__header .photo {
  margin: auto 20px auto 0px;
}

.page__bg_blue {
  background: #f4f7fa;
}
