@use "../mixin/index" as *;

.serviceindexpage__container {
  position: relative;
  overflow: hidden;
}

.servicepage__bg1 {
  background: url(../img/foundation/service/service__bg1.svg) no-repeat center / contain;
  width: 10vw;
  height: 10vw;
  position: absolute;
  top: 7.8%;
  right: -5.1vw;
}

.servicepage__bg2 {
  background: url(../img/foundation/service/service__bg2.svg) no-repeat center / contain;
  width: 16.45vw;
  height: 13.48vw;
  position: absolute;
  top: 13.88%;
  left: -11.4vw;
  z-index: 0;
}

.servicepage__bg3 {
  background: url(../img/foundation/service/service__bg3.svg) no-repeat center / contain;
  width: 39.53vw;
  height: 32.5vw;
  position: absolute;
  top: 38.34%;
  left: -3.17vw;
  z-index: 0;
}

.servicepage__bg4 {
  background: url(../img/foundation/service/service__bg4.svg) no-repeat center / contain;
  width: 13.48vw;
  height: 11.09vw;
  position: absolute;
  top: 54.88%;
  right: 8.17vw;
  z-index: 0;
}

.servicepage__bg5 {
  background: url(../img/foundation/service/service__bg5.svg) no-repeat center / contain;
  width: 27.81vw;
  height: 22.86vw;
  position: absolute;
  top: 62.92%;
  right: -24.79vw;
  z-index: 0;
  mix-blend-mode: multiply;
}

.serviceindexpage__container,
.servicepage__container {
  .btn {
    width: 220px;
    height: 40px;
    border: 2px solid #2a3b4c;
    background: #2a3b4c;
    border-radius: 40px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    transition: 0.5s all;
    margin-top: 20px;
    @media only screen and (min-width: 1024px) {
      margin-top: 40px;
    }
    &:hover {
      color: #2a3b4c;
      background: #fff;
    }

    a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.btn-pink {
      background: #e75f9f;
      border-color: #e75f9f;
      &:hover {
        color: #e75f9f;
        background: #fff;
      }
    }
  }
}

.page__header {
  p {
    margin-top: 10px;
    text-align: center;
  }
}
