.articlespage__single__sharedbutton li.twitter a {
  background: url(../img/foundation/interview/X.svg) no-repeat center/cover;
}

#toc_container {
  background: #f9f8f8;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 25px 23px;
  margin-bottom: 40px;
  //width: 100% !important;

  .toc_title {
    color: #106ab5;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 10px;
    line-height: 1;
  }

  .toc_list {
    padding-left: 1em;
    li {
      font-size: 14px;
      font-weight: 500;
      a {
        transition: 0.3s all;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0);
        &:hover {
          transition: 0.3s all;
          border-bottom: 1px solid #2a3b4c;
        }
      }
    }

    span {
      display: none;
    }

    ul {
      padding-left: 1.5em;
    }
  }
}

.mokuji {
  display: none;
  @media only screen and (min-width: 769px) {
    display: block;
    background: #f9f8f8;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    padding: 25px 23px;
    margin-bottom: 40px;

    & > div > h3 {
      color: #106ab5;
      font-size: 15px;
      font-weight: 700;
    }

    .toc_widget_list {
      padding-left: 1em;
      li {
        font-size: 14px;
        font-weight: 500;

        a {
          transition: 0.3s ease-in;
          border-bottom: 1px solid rgba($color: #000000, $alpha: 0);
          &:hover {
            //text-decoration: underline;
            transition: 0.3s ease-in;
            border-bottom: 1px solid #2a3b4c;
          }
        }
      }
      span {
        display: none;
      }

      ul {
        padding-left: 1.5em;
      }
    }
  }
}

.articlespage__single__entry {
  @media only screen and (min-width: 1024px) {
    h2,
    h3 {
      span {
        //position: relative;
        &::before {
          content: "";
          display: block;
          margin-top: -150px;
          padding-top: 150px;
        }
      }
    }
  }
}

.articlespage__single__item {
  .body {
    .category {
      display: inline-block;
      background: #ebf4ff;
      font-size: 12px;
      border-radius: 100vw;
      padding: 0 1em;
      color: #106ab5;
      font-weight: 500;
    }
    .date {
      color: #888888;
      font-size: 12px;
      text-align: left;
      margin: 0.6em 0 0 0.5em;
    }
    h3 {
      font-weight: 700;
    }
  }
}
