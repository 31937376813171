.workspage__container {
  .pager {
    li {
      .text {
        background: #fff;
        border: 1px solid #d8e3ee;
        color: #2a3b4c;
      }
    }
  }
}
