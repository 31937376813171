@use "../mixin/index" as *;

.footer__logo_area {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 40px;
  align-items: center;
  font-size: min(1.56vw, 13px);
}

.footer__nav__container {
  @media only screen and (min-width: 1180px) {
    &:nth-of-type(1) {
      width: 33.3%;
    }
    &:nth-of-type(2) {
      width: 13.9%;
    }
    &:nth-of-type(3) {
      width: 15.2%;
    }
    &:nth-of-type(4) {
      width: 17.2%;
    }
    &:nth-of-type(5) {
      width: 20.4%;
    }
  }
}
ul.footer__nav__service {
  @media only screen and (min-width: 1180px) {
    display: flex;
    flex-wrap: wrap;
    //width: 29.6%;
    li {
      &:nth-of-type(1) {
        order: 1;
        width: 47%;
      }
      &:nth-of-type(2) {
        order: 3;
        width: 100%;
      }
      &:nth-of-type(3) {
        order: 4;
        width: 100%;
      }
      &:nth-of-type(4) {
        order: 5;
        width: 100%;
      }
      &:nth-of-type(5) {
        order: 6;
        width: 100%;
      }
      &:nth-of-type(6) {
        order: 2;
        width: 53%;
      }
    }
  }
}
