@use "../mixin/index" as *;

.servicepage__pr,
.servicepage__flow {
  padding: 40px 20px;
  @media only screen and (min-width: 641px) {
    padding: 40px 30px;
  }
  @media only screen and (min-width: 1024px) {
    padding: 80px 30px;
  }
}

.servicepage__pr {
  padding-bottom: 0;
}

.servicepage__pr__wrap {
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;
  margin-top: 30px;
  @media only screen and (min-width: 641px) {
    justify-content: space-between;
    row-gap: 40px;
    max-width: 1000px;
    margin: 40px auto 0;
  }
}

.servicepage__pr__item {
  background: #fff;
  border: 1px solid #d8e3ee;
  border-radius: 14px;
  box-shadow: 0px 10px 20px rgba($color: #000000, $alpha: 0.04);
  padding: 30px;
  width: 100%;
  @media only screen and (min-width: 641px) {
    border-radius: 20px;
    padding: 40px;
    width: 48%;
  }

  .photo {
    text-align: center;
  }

  .body {
    h3 {
      font-size: min(4.8vw, 24px);
      font-weight: 700;
      text-align: center;
      margin-top: 15px;
    }

    p {
      font-size: 14px;
      margin-top: 12px;
    }
  }
}

.servicepage__pr__wrap2 {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  @media only screen and (min-width: 641px) {
    justify-content: space-between;
    margin-top: 80px;
  }

  &:last-of-type {
    @media only screen and (min-width: 641px) {
      margin-top: 100px;
      .photo {
        order: 2;
      }
      .body {
        order: 1;
      }
    }
  }

  .photo {
    width: 100%;
    text-align: center;
    @media only screen and (min-width: 641px) {
      width: 48.3%;
    }
  }

  .body {
    @media only screen and (min-width: 641px) {
      width: 45.7%;
    }
    h3 {
      font-size: min(5.333vw, 30px);
      font-weight: 700;
    }

    p {
      font-size: 14px;
      margin-top: 12px;
      @media only screen and (min-width: 641px) {
        margin-top: 30px;
      }
    }
  }
}

.influencer__flow {
  margin-bottom: 0;
}
