@use "../mixin/index" as *;

.servicepage__use,
.servicepage__pic_kind {
  padding: 40px 20px;
  @media only screen and (min-width: 641px) {
    padding: 40px 30px;
  }
  @media only screen and (min-width: 1024px) {
    padding: 80px 30px;
  }
}

.servicepage__pic_kind__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 30px;
  margin-top: 20px;
  @media only screen and (min-width: 1024px) {
    max-width: 1000px;
    margin: 0 auto;
    row-gap: 60px;
    margin-top: 40px;
  }
}

.servicepage__pic_kind__item {
  width: 100%;
  @media only screen and (min-width: 641px) {
    width: 47%;
  }

  h3 {
    color: #106ab5;
    font-weight: 700;
    font-size: min(4.8vw, 24px);
  }
  p {
    margin-top: 10px;
    font-size: 14px;
    @media only screen and (min-width: 641px) {
      margin-top: 20px;
    }
  }
}
