@use "../mixin/index" as *;

.toppage__mainimage {
  background: #fff;
  @media only screen and (min-width: 769px) {
    height: 45.72vw;
  }
  @media only screen and (min-width: 1560px) {
    /*  height: 878px;*/
    //height: calc(42.55vw + 61px);
  }
  .line__left {
    background: url(../img/foundation/top/mainimage__bg1.svg) no-repeat center / contain;
    width: 87.2vw;
    height: 43.46vw;
    transition: 0.8s all;
    transition-delay: 1.8s;
    &::before,
    &::after {
      display: none;
    }
    @media only screen and (min-width: 769px) {
      width: 86.82vw;
      height: 43.33vw;
      left: -30%;
      opacity: 0;
      bottom: 0;
      top: auto;
    }
    @media only screen and (min-width: 1024px) {
    }
  }
  &.is_active .line__left {
    left: -32px;
    top: 10px;
    @media only screen and (min-width: 769px) {
      left: -17.7vw;
      top: 0.23vw;
      opacity: 1;
    }
    @media only screen and (min-width: 1024px) {
      //left: -17.7vw;
      //left: -15.88vw;
      left: calc(50% - 66.35vw);
      top: auto;
      bottom: 0;
    }
  }

  &.is_active .line__right {
    display: none;
    @media only screen and (min-width: 1024px) {
      display: block;
      bottom: auto;
      right: auto;
      left: calc(50% - 10.52vw);
      top: -6vw;
    }
  }

  .line__right {
    background: url(../img/foundation/top/mainimage__bg2.svg) no-repeat center / contain;
    width: 40vw;
    height: 40vw;
    &::before,
    &::after {
      display: none;
    }
    @media only screen and (min-width: 769px) {
      width: 10.52vw;
      height: 10.52vw;
    }
  }

  .copy {
    @media only screen and (min-width: 769px) {
      height: 100%;
      width: 100%;
      max-width: 1565px;
    }
    h2 {
      background: url(../img/foundation/top/mainimage__text.svg) no-repeat center / contain;
      width: 93.06vw;
      height: 30.94vw;
      z-index: 2;
      transition: all 0.5s;
      @media only screen and (min-width: 769px) {
        width: 39.21vw;
        height: 14.89vw;
        position: relative;
        top: 3.6vw;
        left: calc(50% - 40vw);
        margin-bottom: 40px;
      }
      @media only screen and (min-width: 1024px) {
        margin-bottom: 7.5vw;
      }
      @media only screen and (min-width: 1565px) {
        left: 30px;
      }
    }

    p {
      margin: 1.8em 0 30px;
      padding: 0 20px;
      animation: fadein 0.5s ease 0.5s 1 forwards;
      opacity: 0;
      @media only screen and (min-width: 769px) {
        width: 50%;
        font-size: min(1.432vw, 18px);
        margin: 0;
        //padding: 0 0 0 30px;
        z-index: 1;
        line-height: 1.77;
        letter-spacing: 0.025em;
        position: relative;
        left: calc(50% - 40vw);
        top: 0;
        padding: 0;
      }
      @media only screen and (min-width: 1565px) {
        left: 30px;
      }
    }

    .btn {
      width: 90%;
      margin: 0 auto;
      transition-delay: 1s;
      transition: all 0.5s;
      @media only screen and (min-width: 769px) {
        width: auto;
        // margin: 0 0 0 30px;
        position: relative;
        left: calc(50% - 40vw);
      }
      a {
        @media only screen and (min-width: 769px) {
          font-size: min(1.823vw, 24px);
          display: inline-block;
          width: auto;
          height: auto;
          padding: 1em 2em;
          margin-top: 20px;
        }
        @media only screen and (min-width: 1565px) {
          margin-top: 3.83vw;
        }
      }
    }
  }

  @keyframes fadein {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .image {
    background: url(../img/foundation/top/mainimage__logo.svg) no-repeat center / contain;
    @media only screen and (min-width: 769px) {
      width: 54.58vw;
      height: 41.14vw;
      position: absolute;
      top: 20px;
      right: auto;
      left: 51%;
      opacity: 0;
      z-index: 0;
      transition-delay: 1.5s;
      transition: 0.5s all;
    }
  }

  &.is_active .image {
    @media only screen and (min-width: 769px) {
      width: 54.58vw;
      height: 41.14vw;
      position: absolute;
      top: -0.05vw;
      right: auto;
      left: 51%;
      opacity: 1;
    }
  }
}

.toppage__service {
  background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(244, 247, 250, 1) 10%);
  margin-top: -60px;
  overflow: hidden;
  @media only screen and (min-width: 769px) {
    margin-top: -70px;
  }
  @media only screen and (min-width: 1024px) {
    //padding-top: 120px;
  }
}

.toppage__service__bg1 {
  background: url(../img/foundation/top/service__bg1.svg) no-repeat center / contain;
  width: 10.15vw;
  height: 10.15vw;
  position: absolute;
  top: 0;
  right: -5.2vw;
}

.toppage__service__bg2 {
  background: url(../img/foundation/top/service__bg2.svg) no-repeat center / contain;
  width: 39.53vw;
  height: 32.5vw;
  position: absolute;
  top: 45%;
  left: -4.06vw;
  z-index: 0;
}

.toppage__service__bg3 {
  background: url(../img/foundation/top/service__bg3.svg) no-repeat center / contain;
  width: 13.48vw;
  height: 11.09vw;
  position: absolute;
  top: 50%;
  right: 8.22vw;
  z-index: 0;
}

.toppage__service__bg4 {
  background: url(../img/foundation/top/service__bg4.svg) no-repeat center / contain;
  width: 27.81vw;
  height: 22.86vw;
  position: absolute;
  top: 61%;
  right: -24.79vw;
  z-index: 0;
}

.toppage__service__bg5 {
  background: url(../img/foundation/top/service__bg5.svg) no-repeat center / contain;
  width: 16.45vw;
  height: 13.48vw;
  position: absolute;
  bottom: 1.65%;
  left: -11.19vw;
  z-index: 0;
  mix-blend-mode: multiply;
}

.toppage__service__item {
  z-index: 1;
  a {
    padding-top: 20px;
    @media only screen and (min-width: 769px) {
      padding-top: 30px;
    }
    .btn.btn-pink {
      background: #e75f9f;
      border-color: #e75f9f;
    }
    &:hover {
      .btn.btn-pink {
        color: #e75f9f;
        background: #fff;
      }
    }
  }
}

.toppage__service__popularity {
  position: relative;
  z-index: 1;
  text-align: center;
  margin-bottom: 40px;
  @media only screen and (min-width: 769px) {
    margin-bottom: 80px;
  }

  p {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 700;
    @media only screen and (min-width: 769px) {
      margin-bottom: 40px;
    }
  }

  a {
    transition: 0.3s ease-in-out;
    &:hover {
      opacity: 0.7;
      transition: 0.3s ease-in-out;
    }
  }
}

a.toppage__company {
  background: url(../img/foundation/top/company__bg.jpg) no-repeat top right #1b72b9;
  background-size: cover;
  color: #fff;
  padding: 30px 20px 50px;
  display: block;
  @media only screen and (min-width: 769px) {
    padding: 60px 30px 100px;
    background-size: contain;
  }

  &:hover {
    .toppage__company__arrow {
      background: rgba(255, 255, 255, 0.65);
      transition: 0.4s ease-in-out;
      width: 195px;
      height: 195px;
      &::before {
        top: calc(50% - 10px);
        left: calc(50% - 5px);
      }
      &::after {
        content: "more";
        position: absolute;
        top: calc(50% + 1em);
        left: calc(50% - 1.5em);
        font-weight: 700;
        transition: 0.4s ease-in-out;
        opacity: 1;
      }
    }
  }
}

.toppage__company__body {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.toppage__company__text {
  width: 100%;
  @media only screen and (min-width: 769px) {
    width: calc(100% - 60px);
    padding-right: 50px;
  }
  @media only screen and (min-width: 1024px) {
    width: calc(100% - 195px);
    padding-right: 0;
  }

  h3 {
    font-weight: 700;
    font-size: min(5.867vw, 32px);
    @media only screen and (min-width: 769px) {
      font-size: min(3.641vw, 40px);
    }
  }

  p {
    margin-top: 1em;
  }
}

.toppage__company__arrow {
  width: 60px;
  height: 60px;
  margin: 20px auto 0;
  background: rgba(255, 255, 255, 0.4);
  position: relative;
  border-radius: 100vw;
  &::before {
    content: "";
    background: url(../img/foundation/top/company__arrow.svg) center center no-repeat;
    background-size: contain;
    width: 10px;
    height: 20px;
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 5px);
  }
  &::after {
    content: "more";
    position: absolute;
    top: calc(50% + 1em);
    left: calc(50% - 1.5em);
    font-weight: 700;
    transition: 0.4s ease-in-out;
    opacity: 0;
  }
  @media only screen and (min-width: 1024px) {
    transition: 0.4s ease-in-out;
  }
}

.toppage__works,
.toppage__blog {
  background: #f4f7fa;
}

.toppage__interview {
  background: #fff;
}

.toppage__interview__item {
  position: relative;
  .toppage__interview__label {
    display: inline-block;
    background: #106ab5;
    color: #fff;
    position: absolute;
    top: 176px;
    left: 22px;
    padding: 0.5em;
    border-radius: 5px;
    font-weight: 500;
    @media only screen and (min-width: 641px) {
      top: 210px;
    }
  }
}

.toppage__interview__text {
  padding: 30px 22px 0;

  p {
    &.name {
      color: #106ab5;
      font-size: 13px;
    }
    &.title {
      margin-top: 0.7em;
      line-height: 2;
      letter-spacing: 0.025em;
      font-size: 15px;
    }
  }
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
}
