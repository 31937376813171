@use "../mixin/index" as *;

.interviewpage__section {
  padding: 0 20px;
  @media only screen and (min-width: 1200px) {
    padding: 0;
  }
}

.interviewpage__list {
  @media only screen and (min-width: 641px) {
    justify-content: flex-start;
    column-gap: 2%;
  }
}

.interviewpage__item {
  @media only screen and (min-width: 641px) {
    width: 32%;
    margin-right: 0;
    min-width: auto;
  }
}
