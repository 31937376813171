@use "../mixin/index" as *;

.servicepage__kind,
.servicepage__operation {
  padding: 40px 20px;
  @media only screen and (min-width: 641px) {
    padding: 40px 30px;
  }
  @media only screen and (min-width: 1024px) {
    padding: 80px 30px;
  }
}

.servicepage__partner {
  @media only screen and (min-width: 769px) {
    margin-top: -250px;
  }
  @media only screen and (min-width: 1024px) {
    margin-top: -300px;
  }

  h2 {
    @media only screen and (min-width: 769px) {
      font-size: min(3.385vw, 30px);
    }
  }
}
.servicepage__partner__wrap {
  max-width: 500px;
  margin: 20px auto 0;
  text-align: center;

  .servicepage__media__item {
    border: none;
    width: 100%;
  }
}

.servicepage__price__wrap {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  margin-top: 20px;
  @media only screen and (min-width: 769px) {
    justify-content: space-between;
    margin-top: 40px;
  }
}

.servicepage__price__item {
  @media only screen and (min-width: 769px) {
    width: 32%;
  }
  .price-title {
    background: #f4f7fa;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    padding: 0.8em;
    @media only screen and (min-width: 1024px) {
      font-size: 26px;
    }
  }

  .price-body {
    padding: 0 1em;
    p {
      font-size: 24px;
    }
    @media only screen and (min-width: 1024px) {
      font-size: 28px;
    }
    .price-note {
      font-size: 14px;
    }
  }
}
p.servicepage__price__note {
  margin-top: 30px;
}

.servicepage__operation {
  margin-bottom: 0;
}

.servicepage__operation__list {
  margin-top: 20px;
  @media only screen and (min-width: 769px) {
    margin-top: 40px;
  }
}

.operation-title {
  text-align: center;
  font-weight: 700;
  padding: 0.9em;
  background: #d8e3ee;
}

.servicepage__ad__flow {
  margin-bottom: 40px;
  @media only screen and (min-width: 769px) {
    margin-bottom: 80px;
  }
  .servicepage__flow {
    margin-bottom: 0;
  }
}
