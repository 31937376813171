.footer__logo_area {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 40px;
  align-items: center;
  font-size: min(1.56vw, 13px);
}

@media only screen and (min-width: 1180px) {
  .footer__nav__container:nth-of-type(1) {
    width: 33.3%;
  }
  .footer__nav__container:nth-of-type(2) {
    width: 13.9%;
  }
  .footer__nav__container:nth-of-type(3) {
    width: 15.2%;
  }
  .footer__nav__container:nth-of-type(4) {
    width: 17.2%;
  }
  .footer__nav__container:nth-of-type(5) {
    width: 20.4%;
  }
}

@media only screen and (min-width: 1180px) {
  ul.footer__nav__service {
    display: flex;
    flex-wrap: wrap;
  }
  ul.footer__nav__service li:nth-of-type(1) {
    order: 1;
    width: 47%;
  }
  ul.footer__nav__service li:nth-of-type(2) {
    order: 3;
    width: 100%;
  }
  ul.footer__nav__service li:nth-of-type(3) {
    order: 4;
    width: 100%;
  }
  ul.footer__nav__service li:nth-of-type(4) {
    order: 5;
    width: 100%;
  }
  ul.footer__nav__service li:nth-of-type(5) {
    order: 6;
    width: 100%;
  }
  ul.footer__nav__service li:nth-of-type(6) {
    order: 2;
    width: 53%;
  }
}

@media only screen and (max-width: 1023px) {
  .header__logo a {
    width: 103px;
    min-width: 103px;
    height: 30px;
  }
}

.mt_0 {
  margin-top: 0;
}

.mb_0 {
  margin-bottom: 0;
}

.pt_0 {
  padding-top: 0;
}

.tabonly {
  display: block;
}
@media only screen and (min-width: 769px) {
  .tabonly {
    display: block;
  }
}
@media only screen and (min-width: 1024px) {
  .tabonly {
    display: none;
  }
}

.pconly {
  display: none;
}
@media only screen and (min-width: 1024px) {
  .pconly {
    display: block;
  }
}

.homepagepage .inpage__header .photo {
  margin: auto 20px auto 0px;
}

.page__bg_blue {
  background: #f4f7fa;
}

.toppage__mainimage {
  background: #fff;
}
@media only screen and (min-width: 769px) {
  .toppage__mainimage {
    height: 45.72vw;
  }
}
@media only screen and (min-width: 1560px) {
  .toppage__mainimage {
    /*  height: 878px;*/
  }
}
.toppage__mainimage .line__left {
  background: url(../img/foundation/top/mainimage__bg1.svg) no-repeat center/contain;
  width: 87.2vw;
  height: 43.46vw;
  transition: 0.8s all;
  transition-delay: 1.8s;
}
.toppage__mainimage .line__left::before, .toppage__mainimage .line__left::after {
  display: none;
}
@media only screen and (min-width: 769px) {
  .toppage__mainimage .line__left {
    width: 86.82vw;
    height: 43.33vw;
    left: -30%;
    opacity: 0;
    bottom: 0;
    top: auto;
  }
}
.toppage__mainimage.is_active .line__left {
  left: -32px;
  top: 10px;
}
@media only screen and (min-width: 769px) {
  .toppage__mainimage.is_active .line__left {
    left: -17.7vw;
    top: 0.23vw;
    opacity: 1;
  }
}
@media only screen and (min-width: 1024px) {
  .toppage__mainimage.is_active .line__left {
    left: calc(50% - 66.35vw);
    top: auto;
    bottom: 0;
  }
}
.toppage__mainimage.is_active .line__right {
  display: none;
}
@media only screen and (min-width: 1024px) {
  .toppage__mainimage.is_active .line__right {
    display: block;
    bottom: auto;
    right: auto;
    left: calc(50% - 10.52vw);
    top: -6vw;
  }
}
.toppage__mainimage .line__right {
  background: url(../img/foundation/top/mainimage__bg2.svg) no-repeat center/contain;
  width: 40vw;
  height: 40vw;
}
.toppage__mainimage .line__right::before, .toppage__mainimage .line__right::after {
  display: none;
}
@media only screen and (min-width: 769px) {
  .toppage__mainimage .line__right {
    width: 10.52vw;
    height: 10.52vw;
  }
}
@media only screen and (min-width: 769px) {
  .toppage__mainimage .copy {
    height: 100%;
    width: 100%;
    max-width: 1565px;
  }
}
.toppage__mainimage .copy h2 {
  background: url(../img/foundation/top/mainimage__text.svg) no-repeat center/contain;
  width: 93.06vw;
  height: 30.94vw;
  z-index: 2;
  transition: all 0.5s;
}
@media only screen and (min-width: 769px) {
  .toppage__mainimage .copy h2 {
    width: 39.21vw;
    height: 14.89vw;
    position: relative;
    top: 3.6vw;
    left: calc(50% - 40vw);
    margin-bottom: 40px;
  }
}
@media only screen and (min-width: 1024px) {
  .toppage__mainimage .copy h2 {
    margin-bottom: 7.5vw;
  }
}
@media only screen and (min-width: 1565px) {
  .toppage__mainimage .copy h2 {
    left: 30px;
  }
}
.toppage__mainimage .copy p {
  margin: 1.8em 0 30px;
  padding: 0 20px;
  animation: fadein 0.5s ease 0.5s 1 forwards;
  opacity: 0;
}
@media only screen and (min-width: 769px) {
  .toppage__mainimage .copy p {
    width: 50%;
    font-size: min(1.432vw, 18px);
    margin: 0;
    z-index: 1;
    line-height: 1.77;
    letter-spacing: 0.025em;
    position: relative;
    left: calc(50% - 40vw);
    top: 0;
    padding: 0;
  }
}
@media only screen and (min-width: 1565px) {
  .toppage__mainimage .copy p {
    left: 30px;
  }
}
.toppage__mainimage .copy .btn {
  width: 90%;
  margin: 0 auto;
  transition-delay: 1s;
  transition: all 0.5s;
}
@media only screen and (min-width: 769px) {
  .toppage__mainimage .copy .btn {
    width: auto;
    position: relative;
    left: calc(50% - 40vw);
  }
}
@media only screen and (min-width: 769px) {
  .toppage__mainimage .copy .btn a {
    font-size: min(1.823vw, 24px);
    display: inline-block;
    width: auto;
    height: auto;
    padding: 1em 2em;
    margin-top: 20px;
  }
}
@media only screen and (min-width: 1565px) {
  .toppage__mainimage .copy .btn a {
    margin-top: 3.83vw;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.toppage__mainimage .image {
  background: url(../img/foundation/top/mainimage__logo.svg) no-repeat center/contain;
}
@media only screen and (min-width: 769px) {
  .toppage__mainimage .image {
    width: 54.58vw;
    height: 41.14vw;
    position: absolute;
    top: 20px;
    right: auto;
    left: 51%;
    opacity: 0;
    z-index: 0;
    transition-delay: 1.5s;
    transition: 0.5s all;
  }
}
@media only screen and (min-width: 769px) {
  .toppage__mainimage.is_active .image {
    width: 54.58vw;
    height: 41.14vw;
    position: absolute;
    top: -0.05vw;
    right: auto;
    left: 51%;
    opacity: 1;
  }
}

.toppage__service {
  background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(244, 247, 250) 10%);
  margin-top: -60px;
  overflow: hidden;
}
@media only screen and (min-width: 769px) {
  .toppage__service {
    margin-top: -70px;
  }
}
.toppage__service__bg1 {
  background: url(../img/foundation/top/service__bg1.svg) no-repeat center/contain;
  width: 10.15vw;
  height: 10.15vw;
  position: absolute;
  top: 0;
  right: -5.2vw;
}

.toppage__service__bg2 {
  background: url(../img/foundation/top/service__bg2.svg) no-repeat center/contain;
  width: 39.53vw;
  height: 32.5vw;
  position: absolute;
  top: 45%;
  left: -4.06vw;
  z-index: 0;
}

.toppage__service__bg3 {
  background: url(../img/foundation/top/service__bg3.svg) no-repeat center/contain;
  width: 13.48vw;
  height: 11.09vw;
  position: absolute;
  top: 50%;
  right: 8.22vw;
  z-index: 0;
}

.toppage__service__bg4 {
  background: url(../img/foundation/top/service__bg4.svg) no-repeat center/contain;
  width: 27.81vw;
  height: 22.86vw;
  position: absolute;
  top: 61%;
  right: -24.79vw;
  z-index: 0;
}

.toppage__service__bg5 {
  background: url(../img/foundation/top/service__bg5.svg) no-repeat center/contain;
  width: 16.45vw;
  height: 13.48vw;
  position: absolute;
  bottom: 1.65%;
  left: -11.19vw;
  z-index: 0;
  mix-blend-mode: multiply;
}

.toppage__service__item {
  z-index: 1;
}
.toppage__service__item a {
  padding-top: 20px;
}
@media only screen and (min-width: 769px) {
  .toppage__service__item a {
    padding-top: 30px;
  }
}
.toppage__service__item a .btn.btn-pink {
  background: #e75f9f;
  border-color: #e75f9f;
}
.toppage__service__item a:hover .btn.btn-pink {
  color: #e75f9f;
  background: #fff;
}

.toppage__service__popularity {
  position: relative;
  z-index: 1;
  text-align: center;
  margin-bottom: 40px;
}
@media only screen and (min-width: 769px) {
  .toppage__service__popularity {
    margin-bottom: 80px;
  }
}
.toppage__service__popularity p {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 700;
}
@media only screen and (min-width: 769px) {
  .toppage__service__popularity p {
    margin-bottom: 40px;
  }
}
.toppage__service__popularity a {
  transition: 0.3s ease-in-out;
}
.toppage__service__popularity a:hover {
  opacity: 0.7;
  transition: 0.3s ease-in-out;
}

a.toppage__company {
  background: url(../img/foundation/top/company__bg.jpg) no-repeat top right #1b72b9;
  background-size: cover;
  color: #fff;
  padding: 30px 20px 50px;
  display: block;
}
@media only screen and (min-width: 769px) {
  a.toppage__company {
    padding: 60px 30px 100px;
    background-size: contain;
  }
}
a.toppage__company:hover .toppage__company__arrow {
  background: rgba(255, 255, 255, 0.65);
  transition: 0.4s ease-in-out;
  width: 195px;
  height: 195px;
}
a.toppage__company:hover .toppage__company__arrow::before {
  top: calc(50% - 10px);
  left: calc(50% - 5px);
}
a.toppage__company:hover .toppage__company__arrow::after {
  content: "more";
  position: absolute;
  top: calc(50% + 1em);
  left: calc(50% - 1.5em);
  font-weight: 700;
  transition: 0.4s ease-in-out;
  opacity: 1;
}

.toppage__company__body {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.toppage__company__text {
  width: 100%;
}
@media only screen and (min-width: 769px) {
  .toppage__company__text {
    width: calc(100% - 60px);
    padding-right: 50px;
  }
}
@media only screen and (min-width: 1024px) {
  .toppage__company__text {
    width: calc(100% - 195px);
    padding-right: 0;
  }
}
.toppage__company__text h3 {
  font-weight: 700;
  font-size: min(5.867vw, 32px);
}
@media only screen and (min-width: 769px) {
  .toppage__company__text h3 {
    font-size: min(3.641vw, 40px);
  }
}
.toppage__company__text p {
  margin-top: 1em;
}

.toppage__company__arrow {
  width: 60px;
  height: 60px;
  margin: 20px auto 0;
  background: rgba(255, 255, 255, 0.4);
  position: relative;
  border-radius: 100vw;
}
.toppage__company__arrow::before {
  content: "";
  background: url(../img/foundation/top/company__arrow.svg) center center no-repeat;
  background-size: contain;
  width: 10px;
  height: 20px;
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 5px);
}
.toppage__company__arrow::after {
  content: "more";
  position: absolute;
  top: calc(50% + 1em);
  left: calc(50% - 1.5em);
  font-weight: 700;
  transition: 0.4s ease-in-out;
  opacity: 0;
}
@media only screen and (min-width: 1024px) {
  .toppage__company__arrow {
    transition: 0.4s ease-in-out;
  }
}

.toppage__works,
.toppage__blog {
  background: #f4f7fa;
}

.toppage__interview {
  background: #fff;
}

.toppage__interview__item {
  position: relative;
}
.toppage__interview__item .toppage__interview__label {
  display: inline-block;
  background: #106ab5;
  color: #fff;
  position: absolute;
  top: 176px;
  left: 22px;
  padding: 0.5em;
  border-radius: 5px;
  font-weight: 500;
}
@media only screen and (min-width: 641px) {
  .toppage__interview__item .toppage__interview__label {
    top: 210px;
  }
}

.toppage__interview__text {
  padding: 30px 22px 0;
}
.toppage__interview__text p.name {
  color: #106ab5;
  font-size: 13px;
}
.toppage__interview__text p.title {
  margin-top: 0.7em;
  line-height: 2;
  letter-spacing: 0.025em;
  font-size: 15px;
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
}

.serviceindexpage__container {
  position: relative;
  overflow: hidden;
}

.servicepage__bg1 {
  background: url(../img/foundation/service/service__bg1.svg) no-repeat center/contain;
  width: 10vw;
  height: 10vw;
  position: absolute;
  top: 7.8%;
  right: -5.1vw;
}

.servicepage__bg2 {
  background: url(../img/foundation/service/service__bg2.svg) no-repeat center/contain;
  width: 16.45vw;
  height: 13.48vw;
  position: absolute;
  top: 13.88%;
  left: -11.4vw;
  z-index: 0;
}

.servicepage__bg3 {
  background: url(../img/foundation/service/service__bg3.svg) no-repeat center/contain;
  width: 39.53vw;
  height: 32.5vw;
  position: absolute;
  top: 38.34%;
  left: -3.17vw;
  z-index: 0;
}

.servicepage__bg4 {
  background: url(../img/foundation/service/service__bg4.svg) no-repeat center/contain;
  width: 13.48vw;
  height: 11.09vw;
  position: absolute;
  top: 54.88%;
  right: 8.17vw;
  z-index: 0;
}

.servicepage__bg5 {
  background: url(../img/foundation/service/service__bg5.svg) no-repeat center/contain;
  width: 27.81vw;
  height: 22.86vw;
  position: absolute;
  top: 62.92%;
  right: -24.79vw;
  z-index: 0;
  mix-blend-mode: multiply;
}

.serviceindexpage__container .btn,
.servicepage__container .btn {
  width: 220px;
  height: 40px;
  border: 2px solid #2a3b4c;
  background: #2a3b4c;
  border-radius: 40px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  transition: 0.5s all;
  margin-top: 20px;
}
@media only screen and (min-width: 1024px) {
  .serviceindexpage__container .btn,
.servicepage__container .btn {
    margin-top: 40px;
  }
}
.serviceindexpage__container .btn:hover,
.servicepage__container .btn:hover {
  color: #2a3b4c;
  background: #fff;
}
.serviceindexpage__container .btn a,
.servicepage__container .btn a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.serviceindexpage__container .btn.btn-pink,
.servicepage__container .btn.btn-pink {
  background: #e75f9f;
  border-color: #e75f9f;
}
.serviceindexpage__container .btn.btn-pink:hover,
.servicepage__container .btn.btn-pink:hover {
  color: #e75f9f;
  background: #fff;
}

.page__header p {
  margin-top: 10px;
  text-align: center;
}

@media only screen and (min-width: 769px) {
  .servicepage__about__item .body {
    width: 67.8%;
  }
}
@media only screen and (min-width: 1024px) {
  .servicepage__about__item .body {
    width: calc(100% - 440px);
    padding-left: 10.5%;
  }
}
@media only screen and (min-width: 769px) {
  .servicepage__about__item .photo {
    min-width: 32.2% !important;
  }
}
@media only screen and (min-width: 1024px) {
  .servicepage__about__item .photo {
    min-width: 440px !important;
    margin: 0 !important;
  }
}

.servicepage__point {
  background: linear-gradient(90deg, #f4f7fa 0%, #f4f7fa 50%, #ffffff 51%, #ffffff 100%);
}

.servicepage__point__body {
  background: #f4f7fa;
  width: calc(100% - 40px);
  margin: 0 auto;
  padding: 30px 30px 30px 10px;
}
@media only screen and (min-width: 769px) {
  .servicepage__point__body {
    padding: 50px 40px 50px 20px;
  }
}
@media only screen and (min-width: 1024px) {
  .servicepage__point__body {
    padding: 80px 90px;
    max-width: 1180px;
  }
}

.servicepage__point__item {
  margin-top: 30px;
}
@media only screen and (min-width: 769px) {
  .servicepage__point__item {
    display: flex;
    margin-top: 50px;
  }
}
@media only screen and (min-width: 1024px) {
  .servicepage__point__item {
    margin-top: 80px;
  }
}
@media only screen and (min-width: 769px) {
  .servicepage__point__item .body {
    width: 63%;
    padding-left: 30px;
  }
}
@media only screen and (min-width: 1024px) {
  .servicepage__point__item .body {
    padding-left: 60px;
  }
}
.servicepage__point__item .body p.point-label {
  margin-top: 10px;
  color: #e75f9f;
  font-weight: 700;
}
@media only screen and (min-width: 769px) {
  .servicepage__point__item .body p.point-label {
    margin-top: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .servicepage__point__item .body p.point-label {
    font-size: 15px;
  }
}
.servicepage__point__item .body p.point-label span {
  font-size: 1.5em;
}
@media only screen and (min-width: 1024px) {
  .servicepage__point__item .body p.point-label span {
    font-size: 20px;
  }
}
.servicepage__point__item .body h3 {
  font-size: min(4.8vw, 24px);
  font-weight: 700;
}
.servicepage__point__item .body p {
  margin-top: 20px;
  font-weight: 500;
}
@media only screen and (min-width: 1024px) {
  .servicepage__point__item .body p {
    margin-top: 40px;
  }
}
@media only screen and (min-width: 769px) {
  .servicepage__point__item .photo {
    width: 37%;
    max-width: 370px;
  }
}
@media only screen and (min-width: 769px) {
  .servicepage__point__item:nth-of-type(2) .photo {
    order: 2;
  }
  .servicepage__point__item:nth-of-type(2) .body {
    order: 1;
    padding-left: 0;
    padding-right: 30px;
  }
}
@media only screen and (min-width: 1024px) {
  .servicepage__point__item:nth-of-type(2) .body {
    padding-right: 60px;
  }
}

@media only screen and (min-width: 769px) {
  .homepage-point {
    padding-bottom: 295px;
  }
}

.servicepage__media {
  width: calc(100% - 40px);
  margin: 30px auto 0;
}
@media only screen and (min-width: 769px) {
  .servicepage__media {
    margin-top: -230px;
    width: calc(100% - 80px);
    max-width: 800px;
  }
}

.servicepage__media__wrap {
  border: 1px solid #d8e3ee;
  background: #fff;
  border-radius: 14px;
  margin-top: 20px;
}
@media only screen and (min-width: 769px) {
  .servicepage__media__wrap {
    display: flex;
    border-radius: 20px;
  }
}

.servicepage__media__item {
  padding: 30px;
  text-align: center;
}
@media only screen and (min-width: 641px) {
  .servicepage__media__item {
    width: 50%;
    padding: 40px;
  }
}
.servicepage__media__item:nth-of-type(1) {
  border-bottom: 1px solid #d8e3ee;
}
@media only screen and (min-width: 641px) {
  .servicepage__media__item:nth-of-type(1) {
    border-bottom: none;
    border-right: 1px solid #d8e3ee;
  }
}
.servicepage__media__item:nth-of-type(1) .photo {
  min-height: 148px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.servicepage__media__item .photo {
  text-align: center;
}
.servicepage__media__item .body p {
  padding-top: 20px;
  text-align: center;
  font-weight: 500;
}
@media only screen and (min-width: 641px) {
  .servicepage__media__item .body p {
    font-size: min(2.496vw, 19px);
  }
}

.servicepage__analysis,
.servicepage__support {
  margin-top: 50px;
}
@media only screen and (min-width: 769px) {
  .servicepage__analysis,
.servicepage__support {
    margin-top: 70px;
  }
}
@media only screen and (min-width: 1024px) {
  .servicepage__analysis,
.servicepage__support {
    margin-top: 90px;
  }
}

.servicepage__analysis__item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: calc(100% - 40px);
  margin: 30px auto 0;
}
@media only screen and (min-width: 769px) {
  .servicepage__analysis__item {
    width: calc(100% - 60px);
  }
}
@media only screen and (min-width: 1240px) {
  .servicepage__analysis__item {
    width: 100%;
  }
}
.servicepage__analysis__item .photo {
  width: 80%;
  max-width: 440px;
  margin: 0 auto;
}
@media only screen and (min-width: 641px) {
  .servicepage__analysis__item .photo {
    width: 37.28%;
  }
}
.servicepage__analysis__item .body {
  width: 100%;
  margin-top: 30px;
}
@media only screen and (min-width: 641px) {
  .servicepage__analysis__item .body {
    width: 62.72%;
    padding-left: 40px;
  }
}
@media only screen and (min-width: 1024px) {
  .servicepage__analysis__item .body {
    padding-left: 10.5%;
  }
}
.servicepage__analysis__item .body h3 {
  font-size: min(4.8vw, 24px);
  font-weight: 700;
}
.servicepage__analysis__item .body p {
  font-size: 14px;
  margin-top: 20px;
}
@media only screen and (min-width: 769px) {
  .servicepage__analysis__item .body p {
    margin-top: 30px;
  }
}

.servicepage__support {
  margin-bottom: 50px;
  max-width: 1000px;
}
@media only screen and (min-width: 1240px) {
  .servicepage__support {
    margin-bottom: 100px;
  }
}

.servicepage__support__wrap {
  width: calc(100% - 40px);
  margin: 30px auto 0;
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;
  justify-content: space-between;
}
@media only screen and (min-width: 769px) {
  .servicepage__support__wrap {
    width: calc(100% - 60px);
  }
}
@media only screen and (min-width: 1240px) {
  .servicepage__support__wrap {
    width: 100%;
    margin-top: 40px;
  }
}

.servicepage__support__item {
  background: #fff;
  border: 1px solid #d8e3ee;
  border-radius: 14px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04);
  padding: 30px 25px;
}
@media only screen and (min-width: 641px) {
  .servicepage__support__item {
    width: 48%;
  }
}
@media only screen and (min-width: 769px) {
  .servicepage__support__item {
    border-radius: 20px;
  }
}
@media only screen and (min-width: 1024px) {
  .servicepage__support__item {
    width: 23.5%;
  }
}
.servicepage__support__item h3 {
  text-align: center;
  font-size: min(4.533vw, 20px);
  font-weight: 700;
}
.servicepage__support__item p {
  margin-top: 15px;
  font-size: 14px;
  font-weight: 500;
}
.servicepage__support__item .btn {
  text-align: center;
  width: auto;
  padding: 0.5em 1.5em;
  height: auto;
  margin: 20px auto 0;
}

.servicepage__site_list {
  padding: 40px 20px;
}
@media only screen and (min-width: 769px) {
  .servicepage__site_list {
    padding: 50px 30px;
  }
}
@media only screen and (min-width: 1024px) {
  .servicepage__site_list {
    padding: 80px 30px;
  }
}

.servicepage__partner {
  background: none;
}
@media only screen and (min-width: 769px) {
  .servicepage__partner h2 {
    font-size: 30px;
  }
}

.servicepage__item__wrap {
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;
  margin-top: 20px;
}
@media only screen and (min-width: 641px) {
  .servicepage__item__wrap {
    column-gap: 4%;
  }
}
@media only screen and (min-width: 769px) {
  .servicepage__item__wrap {
    column-gap: 4.25%;
    margin-top: 40px;
  }
}

.servicepage__item {
  background: #fff;
  border: 1px solid #d8e3ee;
  border-radius: 14px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04);
  padding: 30px 25px;
}
@media only screen and (min-width: 641px) {
  .servicepage__item {
    width: 48%;
    padding: 30px 40px;
  }
}
@media only screen and (min-width: 769px) {
  .servicepage__item {
    border-radius: 20px;
    width: 30.5%;
  }
}
.servicepage__item .photo {
  text-align: center;
}
.servicepage__item .body h3 {
  font-size: min(4.8vw, 24px);
  font-weight: 700;
  text-align: center;
  margin-top: 15px;
}
.servicepage__item .body p {
  font-size: 14px;
  margin-top: 12px;
}

.servicepage__flow__body {
  margin-top: 30px;
}

.servicepage__flow__item {
  display: flex;
  justify-content: center;
  border: none;
  box-shadow: none;
  filter: none;
  border-radius: 0;
  padding: 0;
  padding-bottom: 47px;
  margin-bottom: 20px;
  background: url(../img/foundation/service/dot.svg) center bottom no-repeat;
  background-size: 3px 27px;
}
@media only screen and (min-width: 641px) {
  .servicepage__flow__item {
    background-position: left 48px bottom;
    padding-bottom: 37px;
    margin-bottom: 5px;
    align-items: flex-start;
  }
}
.servicepage__flow__item:last-of-type {
  background: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.servicepage__flow__item .photo {
  width: 100px;
  max-width: 100px;
  min-width: 100px;
}
.servicepage__flow__item .photo p {
  color: #dd488e;
  font-weight: 700;
  font-size: min(4.267vw, 20px);
  text-align: center;
}
.servicepage__flow__item .body {
  background: #fff;
  border: 1px solid #d8e3ee;
  border-radius: 14px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04);
  padding: 30px 25px;
}
@media only screen and (min-width: 641px) {
  .servicepage__flow__item .body h3 {
    margin-bottom: 6px;
  }
}

.page__works,
.page__interview,
.page__blog,
.servicepage__question {
  padding: 40px 20px;
}
@media only screen and (min-width: 641px) {
  .page__works,
.page__interview,
.page__blog,
.servicepage__question {
    padding: 40px 30px;
  }
}
@media only screen and (min-width: 1024px) {
  .page__works,
.page__interview,
.page__blog,
.servicepage__question {
    padding: 80px 30px;
  }
}

.page__works__list,
.page__interview__list,
.page__blog__list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
@media only screen and (min-width: 641px) {
  .page__works__list,
.page__interview__list,
.page__blog__list {
    margin-bottom: 40px;
    justify-content: space-between;
    max-width: 1180px;
    margin: 40px auto 0;
  }
}

.page__works__item,
.page__interview__item,
.page__blog__item {
  width: 100%;
  margin: 0 0 20px;
}
@media only screen and (min-width: 641px) {
  .page__works__item,
.page__interview__item,
.page__blog__item {
    width: calc(33% - 10px);
  }
}
.page__works__item a,
.page__interview__item a,
.page__blog__item a {
  background: #fff;
  border: 1px solid #d8e3ee;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-bottom: 28px;
  transition: 0.5s all;
}
.page__works__item a:hover,
.page__interview__item a:hover,
.page__blog__item a:hover {
  opacity: 0.7;
  transition: 0.5s all;
}
.page__works__item .photo,
.page__interview__item .photo,
.page__blog__item .photo {
  margin-bottom: 26px;
  position: relative;
}
.page__works__item .category,
.page__interview__item .category,
.page__blog__item .category {
  position: absolute;
  left: 18px;
  bottom: 0;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
  height: 28px;
  line-height: 28px;
  background: #106ab5;
  border-radius: 5px;
  color: #fff;
  font-size: 13px;
  padding: 0 10px;
  letter-spacing: 0.03em;
}
.page__works__item h3,
.page__interview__item h3,
.page__blog__item h3 {
  padding: 0 22px;
  line-height: 15px;
  font-weight: 700;
  line-height: 30px;
  flex-grow: 1;
}

.page__interview__item h3 {
  color: #106ab5;
}
.page__interview__item p.title {
  padding: 16px 22px 0;
}

.page__blog__item h3 {
  padding: 0 22px;
  line-height: 15px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 20px;
  flex-grow: 1;
}
.page__blog__item .info {
  padding: 0 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page__blog__item .info ul.blog_category li {
  padding: 0 10px;
  line-height: 20px;
  font-size: 12px;
  color: #106ab5;
  height: 20px;
  background: #ebf4ff;
  font-weight: 500;
  letter-spacing: 0.03em;
  border-radius: 25px;
}
.page__blog__item .info .date {
  color: #888;
  font-size: 12px;
  letter-spacing: 0.03em;
}

.servicepage__question {
  padding-bottom: 0;
}

.servicepage__kind,
.servicepage__operation {
  padding: 40px 20px;
}
@media only screen and (min-width: 641px) {
  .servicepage__kind,
.servicepage__operation {
    padding: 40px 30px;
  }
}
@media only screen and (min-width: 1024px) {
  .servicepage__kind,
.servicepage__operation {
    padding: 80px 30px;
  }
}

@media only screen and (min-width: 769px) {
  .servicepage__partner {
    margin-top: -250px;
  }
}
@media only screen and (min-width: 1024px) {
  .servicepage__partner {
    margin-top: -300px;
  }
}
@media only screen and (min-width: 769px) {
  .servicepage__partner h2 {
    font-size: min(3.385vw, 30px);
  }
}

.servicepage__partner__wrap {
  max-width: 500px;
  margin: 20px auto 0;
  text-align: center;
}
.servicepage__partner__wrap .servicepage__media__item {
  border: none;
  width: 100%;
}

.servicepage__price__wrap {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  margin-top: 20px;
}
@media only screen and (min-width: 769px) {
  .servicepage__price__wrap {
    justify-content: space-between;
    margin-top: 40px;
  }
}

@media only screen and (min-width: 769px) {
  .servicepage__price__item {
    width: 32%;
  }
}
.servicepage__price__item .price-title {
  background: #f4f7fa;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  padding: 0.8em;
}
@media only screen and (min-width: 1024px) {
  .servicepage__price__item .price-title {
    font-size: 26px;
  }
}
.servicepage__price__item .price-body {
  padding: 0 1em;
}
.servicepage__price__item .price-body p {
  font-size: 24px;
}
@media only screen and (min-width: 1024px) {
  .servicepage__price__item .price-body {
    font-size: 28px;
  }
}
.servicepage__price__item .price-body .price-note {
  font-size: 14px;
}

p.servicepage__price__note {
  margin-top: 30px;
}

.servicepage__operation {
  margin-bottom: 0;
}

.servicepage__operation__list {
  margin-top: 20px;
}
@media only screen and (min-width: 769px) {
  .servicepage__operation__list {
    margin-top: 40px;
  }
}

.operation-title {
  text-align: center;
  font-weight: 700;
  padding: 0.9em;
  background: #d8e3ee;
}

.servicepage__ad__flow {
  margin-bottom: 40px;
}
@media only screen and (min-width: 769px) {
  .servicepage__ad__flow {
    margin-bottom: 80px;
  }
}
.servicepage__ad__flow .servicepage__flow {
  margin-bottom: 0;
}

.servicepage__operation_sns,
.servicepage__operation_sp,
.servicepage__staff,
.servicepage__sns_case {
  padding: 40px 20px;
}
@media only screen and (min-width: 641px) {
  .servicepage__operation_sns,
.servicepage__operation_sp,
.servicepage__staff,
.servicepage__sns_case {
    padding: 40px 30px;
  }
}
@media only screen and (min-width: 1024px) {
  .servicepage__operation_sns,
.servicepage__operation_sp,
.servicepage__staff,
.servicepage__sns_case {
    padding: 80px 30px;
  }
}

.servicepage__operation_sp {
  display: flex;
  flex-wrap: wrap;
  row-gap: 50px;
  max-width: 1000px;
  margin: 0 auto;
}
@media only screen and (min-width: 1024px) {
  .servicepage__operation_sp {
    padding-top: 100px;
    row-gap: 80px;
  }
}

.servicepage__operation_sp__item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.servicepage__operation_sp__item .photo {
  text-align: center;
  width: 100%;
}
@media only screen and (min-width: 641px) {
  .servicepage__operation_sp__item .photo {
    width: 29.4%;
  }
}
.servicepage__operation_sp__item .body {
  margin-top: 30px;
}
@media only screen and (min-width: 641px) {
  .servicepage__operation_sp__item .body {
    width: 60%;
    padding-top: 10%;
  }
}
@media only screen and (min-width: 1024px) {
  .servicepage__operation_sp__item .body {
    width: 54%;
  }
}
.servicepage__operation_sp__item .body h3 {
  font-size: min(5.333vw, 30px);
  font-weight: 700;
}
.servicepage__operation_sp__item .body p {
  margin-top: 20px;
}
@media only screen and (min-width: 641px) {
  .servicepage__operation_sp__item:last-of-type .photo {
    order: 2;
  }
  .servicepage__operation_sp__item:last-of-type .body {
    order: 1;
  }
}

.servicepage__staff__item {
  background: #fff;
  border-radius: 14px;
  padding: 30px;
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (min-width: 769px) {
  .servicepage__staff__item {
    border-radius: 20px;
    margin-top: 40px;
    justify-content: space-between;
    padding: 6% 10% 6% 11%;
    max-width: 1000px;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1024px) {
  .servicepage__staff__item {
    padding: 60px 100px 60px 110px;
  }
}
.servicepage__staff__item:last-of-type .servicepage__staff__info {
  justify-content: flex-start;
}
@media only screen and (min-width: 1024px) {
  .servicepage__staff__item:last-of-type .servicepage__staff__info {
    column-gap: 5em;
  }
}
.servicepage__staff__item:last-of-type .servicepage__staff__info p.date {
  width: 100%;
}

.servicepage__staff__photo {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}
@media only screen and (min-width: 769px) {
  .servicepage__staff__photo {
    margin-bottom: 0;
    width: 50%;
    text-align: left;
  }
}
@media only screen and (min-width: 1024px) {
  .servicepage__staff__photo {
    width: 331px;
  }
}

.servicepage__staff__body {
  width: 100%;
}
@media only screen and (min-width: 769px) {
  .servicepage__staff__body {
    width: calc(50% - 30px);
  }
}
@media only screen and (min-width: 1024px) {
  .servicepage__staff__body {
    width: calc(100% - 331px);
    padding-left: 90px;
  }
}
.servicepage__staff__body img {
  display: block;
  margin: 0 auto;
}
@media only screen and (min-width: 1024px) {
  .servicepage__staff__body img {
    width: 314px;
    margin: 0;
  }
}
.servicepage__staff__body h3 {
  font-size: min(5.333vw, 35px);
  font-weight: 700;
  margin-top: 0.85em;
  letter-spacing: 0.1em;
}
.servicepage__staff__body p.name-kana {
  color: #106ab5;
  font-size: 14px;
  font-weight: 500;
  margin-top: 0.5em;
  letter-spacing: 0.15em;
}
.servicepage__staff__body p {
  margin-top: 2em;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.03em;
}
@media only screen and (min-width: 1024px) {
  .servicepage__staff__body p {
    margin-top: 20px;
  }
}

.servicepage__staff__info {
  border-top: 1px solid #d8e3ee;
  margin-top: 30px;
  padding-top: 20px;
  width: 100%;
}
@media only screen and (min-width: 769px) {
  .servicepage__staff__info {
    display: flex;
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 1024px) {
  .servicepage__staff__info {
    margin-top: 40px;
    padding-top: 30px;
    justify-content: space-between;
  }
}
.servicepage__staff__info p {
  color: #106ab5;
  font-weight: 500;
}
@media only screen and (min-width: 769px) {
  .servicepage__staff__info p {
    padding-right: 2em;
  }
}
@media only screen and (min-width: 1024px) {
  .servicepage__staff__info p {
    padding-right: 0;
    font-size: 18px;
  }
}
.servicepage__staff__info p:nth-of-type(4) {
  margin-top: 20px;
}
@media only screen and (min-width: 769px) {
  .servicepage__staff__info p:nth-of-type(4) {
    width: 100%;
  }
}
.servicepage__staff__info p.achievement,
.servicepage__staff__info p.date {
  color: #2a3b4c;
}

@media only screen and (min-width: 1024px) {
  p.achievement {
    font-size: 14px;
    width: 80%;
  }
}

p.date {
  margin-top: 20px;
  text-align: right;
  font-size: 13px;
  font-weight: 400;
}
@media only screen and (min-width: 769px) {
  p.date {
    width: 100%;
    padding-right: 0;
  }
}
@media only screen and (min-width: 1024px) {
  p.date {
    width: auto;
    margin-top: 0;
  }
}

.servicepage__sns_case__item {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  margin-top: 40px;
}
@media only screen and (min-width: 641px) {
  .servicepage__sns_case__item {
    align-items: center;
    justify-content: space-between;
    max-width: 1000px;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1024px) {
  .servicepage__sns_case__item {
    margin-top: 80px;
  }
}
.servicepage__sns_case__item:nth-of-type(1) {
  margin-top: 40px;
}
.servicepage__sns_case__item .photo {
  width: 100%;
  text-align: center;
}
@media only screen and (min-width: 641px) {
  .servicepage__sns_case__item .photo {
    width: 46.2%;
  }
}
.servicepage__sns_case__item .body {
  width: 100%;
}
@media only screen and (min-width: 641px) {
  .servicepage__sns_case__item .body {
    width: 45.8%;
  }
}
.servicepage__sns_case__item .body h4 {
  margin-top: 20px;
  font-size: min(4.267vw, 18px);
  font-weight: 500;
}
@media only screen and (min-width: 641px) {
  .servicepage__sns_case__item .body h4 {
    margin-top: 30px;
  }
}
.servicepage__sns_case__item .body p {
  margin-top: 10px;
  font-size: 14px;
}
@media only screen and (min-width: 641px) {
  .servicepage__sns_case__item .body p {
    margin-top: 20px;
  }
}
.servicepage__sns_case__item .body .servicepage__sns_case__title {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d8e3ee;
  padding-bottom: 15px;
}
@media only screen and (min-width: 641px) {
  .servicepage__sns_case__item .body .servicepage__sns_case__title {
    padding-bottom: 25px;
  }
}
.servicepage__sns_case__item .body .servicepage__sns_case__title h3 {
  display: inline-block;
  margin-right: 1.5em;
  font-size: min(4.8vw, 24px);
  font-weight: 700;
}
.servicepage__sns_case__item .body .servicepage__sns_case__title p {
  font-size: min(3.733vw, 19px);
  margin-top: 0;
}

.servicepage__pr,
.servicepage__flow {
  padding: 40px 20px;
}
@media only screen and (min-width: 641px) {
  .servicepage__pr,
.servicepage__flow {
    padding: 40px 30px;
  }
}
@media only screen and (min-width: 1024px) {
  .servicepage__pr,
.servicepage__flow {
    padding: 80px 30px;
  }
}

.servicepage__pr {
  padding-bottom: 0;
}

.servicepage__pr__wrap {
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;
  margin-top: 30px;
}
@media only screen and (min-width: 641px) {
  .servicepage__pr__wrap {
    justify-content: space-between;
    row-gap: 40px;
    max-width: 1000px;
    margin: 40px auto 0;
  }
}

.servicepage__pr__item {
  background: #fff;
  border: 1px solid #d8e3ee;
  border-radius: 14px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04);
  padding: 30px;
  width: 100%;
}
@media only screen and (min-width: 641px) {
  .servicepage__pr__item {
    border-radius: 20px;
    padding: 40px;
    width: 48%;
  }
}
.servicepage__pr__item .photo {
  text-align: center;
}
.servicepage__pr__item .body h3 {
  font-size: min(4.8vw, 24px);
  font-weight: 700;
  text-align: center;
  margin-top: 15px;
}
.servicepage__pr__item .body p {
  font-size: 14px;
  margin-top: 12px;
}

.servicepage__pr__wrap2 {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
}
@media only screen and (min-width: 641px) {
  .servicepage__pr__wrap2 {
    justify-content: space-between;
    margin-top: 80px;
  }
}
@media only screen and (min-width: 641px) {
  .servicepage__pr__wrap2:last-of-type {
    margin-top: 100px;
  }
  .servicepage__pr__wrap2:last-of-type .photo {
    order: 2;
  }
  .servicepage__pr__wrap2:last-of-type .body {
    order: 1;
  }
}
.servicepage__pr__wrap2 .photo {
  width: 100%;
  text-align: center;
}
@media only screen and (min-width: 641px) {
  .servicepage__pr__wrap2 .photo {
    width: 48.3%;
  }
}
@media only screen and (min-width: 641px) {
  .servicepage__pr__wrap2 .body {
    width: 45.7%;
  }
}
.servicepage__pr__wrap2 .body h3 {
  font-size: min(5.333vw, 30px);
  font-weight: 700;
}
.servicepage__pr__wrap2 .body p {
  font-size: 14px;
  margin-top: 12px;
}
@media only screen and (min-width: 641px) {
  .servicepage__pr__wrap2 .body p {
    margin-top: 30px;
  }
}

.influencer__flow {
  margin-bottom: 0;
}

.servicepage__use,
.servicepage__pic_kind {
  padding: 40px 20px;
}
@media only screen and (min-width: 641px) {
  .servicepage__use,
.servicepage__pic_kind {
    padding: 40px 30px;
  }
}
@media only screen and (min-width: 1024px) {
  .servicepage__use,
.servicepage__pic_kind {
    padding: 80px 30px;
  }
}

.servicepage__pic_kind__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 30px;
  margin-top: 20px;
}
@media only screen and (min-width: 1024px) {
  .servicepage__pic_kind__list {
    max-width: 1000px;
    margin: 0 auto;
    row-gap: 60px;
    margin-top: 40px;
  }
}

.servicepage__pic_kind__item {
  width: 100%;
}
@media only screen and (min-width: 641px) {
  .servicepage__pic_kind__item {
    width: 47%;
  }
}
.servicepage__pic_kind__item h3 {
  color: #106ab5;
  font-weight: 700;
  font-size: min(4.8vw, 24px);
}
.servicepage__pic_kind__item p {
  margin-top: 10px;
  font-size: 14px;
}
@media only screen and (min-width: 641px) {
  .servicepage__pic_kind__item p {
    margin-top: 20px;
  }
}

.companypage .inpage__header .photo {
  margin: auto 41px auto 0;
}

.companypage__container .inpage__header {
  margin-bottom: 0;
}

.companypage__main {
  background: url(../img/foundation/company/bg__photo.jpg) top right 5.2% no-repeat;
  background-size: 32.7vw 29.8vw;
  padding: 40px 20px;
  position: relative;
}
@media only screen and (min-width: 1024px) {
  .companypage__main {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 1024px) {
  .companypage__main__head {
    max-width: 1180px;
    margin: 0 auto;
  }
}
.companypage__main__head h2 {
  line-height: 1.45;
  text-align: left;
}
.companypage__main__head p {
  font-size: min(4vw, 18px);
  margin-top: 30px;
}
@media only screen and (min-width: 1024px) {
  .companypage__main__head p {
    margin-top: 60px;
  }
}
.companypage__main__head p:last-of-type {
  margin-top: 20px;
}
@media only screen and (min-width: 1024px) {
  .companypage__main__head p:last-of-type {
    margin-top: 40px;
  }
}

.companypage__main__bg1 {
  background: url(../img/foundation/company/bg__img1.svg) center center no-repeat;
  background-size: contain;
  width: 17.6vw;
  height: 14.42vw;
  position: absolute;
  top: 33.3%;
  left: 18.44%;
}

.companypage__main__bg2 {
  background: url(../img/foundation/company/bg__img2.svg) center center no-repeat;
  background-size: contain;
  width: 25vw;
  height: 20.52vw;
  position: absolute;
  top: 46.22%;
  left: 61.35%;
}

.companypage__main__item {
  max-width: 853px;
  margin: 35px auto 0;
}
@media only screen and (min-width: 1024px) {
  .companypage__main__item {
    margin-top: 95px;
  }
}
.companypage__main__item h2 {
  font-weight: 400;
  font-size: min(6.4vw, 56px);
  border-bottom: 1px solid #d8e3ee;
  padding-bottom: 10px;
  text-align: left;
}
@media only screen and (min-width: 1024px) {
  .companypage__main__item h2 {
    padding-bottom: 20px;
  }
}
.companypage__main__item h3 {
  margin-top: 20px;
  font-size: min(4.8vw, 24px);
  font-weight: 700;
}
@media only screen and (min-width: 1024px) {
  .companypage__main__item h3 {
    margin-top: 40px;
  }
}
.companypage__main__item p {
  margin-top: 15px;
}
@media only screen and (min-width: 1024px) {
  .companypage__main__item p {
    margin-top: 25px;
  }
}

.companypage__main__expert {
  padding: 40px 20px;
}
@media only screen and (min-width: 641px) {
  .companypage__main__expert {
    padding: 40px 30px;
  }
}
@media only screen and (min-width: 1024px) {
  .companypage__main__expert {
    padding: 95px 30px 80px;
    max-width: 1240px;
    margin: 0 auto;
  }
}

.companypage__expert__list {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  border: 1px solid #d8e3ee;
  border-radius: 14px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04);
  margin-top: 30px;
}
@media only screen and (min-width: 1024px) {
  .companypage__expert__list {
    margin-top: 40px;
  }
}

.companypage__expert__item {
  border-bottom: 1px solid #d8e3ee;
  padding: 30px 25px;
  width: 100%;
}
@media only screen and (min-width: 641px) {
  .companypage__expert__item {
    width: 50%;
  }
}
@media only screen and (min-width: 1024px) {
  .companypage__expert__item {
    padding: 40px;
    border-bottom: none;
    border-right: 1px solid #d8e3ee;
    width: 25%;
  }
}
.companypage__expert__item:last-of-type {
  border-bottom: none;
}
@media only screen and (min-width: 1024px) {
  .companypage__expert__item:last-of-type {
    border-right: none;
  }
}
@media only screen and (min-width: 641px) {
  .companypage__expert__item:nth-of-type(odd) {
    border-right: 1px solid #d8e3ee;
  }
}
.companypage__expert__item .photo {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 113px;
}
.companypage__expert__item .body {
  margin-top: 20px;
}
@media only screen and (min-width: 1024px) {
  .companypage__expert__item .body {
    margin-top: 25px;
  }
}
.companypage__expert__item .body p {
  text-align: center;
  font-weight: 500;
}

.companypage__about {
  padding: 40px 20px;
}
@media only screen and (min-width: 641px) {
  .companypage__about {
    padding: 40px 30px;
  }
}
@media only screen and (min-width: 1024px) {
  .companypage__about {
    padding: 80px 30px;
  }
}

.companypage__about__info {
  background: #fff;
  border: 1px solid #d8e3ee;
  border-radius: 14px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04);
  margin-top: 30px;
  padding: 20px;
}
@media only screen and (min-width: 1024px) {
  .companypage__about__info {
    margin-top: 40px;
    padding: 40px;
  }
}

table.company_table {
  border-top: 1px solid #d8e3ee;
  width: 100%;
}
table.company_table th,
table.company_table td {
  font-weight: 500;
  border-bottom: 1px solid #d8e3ee;
  padding: 20px 0 30px;
  font-size: min(3.467vw, 16px);
  word-break: break-all;
  vertical-align: middle;
}
@media only screen and (min-width: 1024px) {
  table.company_table th,
table.company_table td {
    padding: 30px 0 40px;
  }
}
table.company_table th {
  width: 26%;
  padding-right: 1em;
}
table.company_table td {
  width: 74%;
}
table.company_table .btn {
  margin-top: 10px;
}
@media only screen and (min-width: 768px) {
  table.company_table .btn {
    display: inline-block;
    margin-left: 50px;
  }
}
table.company_table .btn a {
  background: #e75f9f;
  border: 2px solid #e75f9f;
  border-radius: 100vw;
  display: inline-block;
  padding: 0.5em 3em;
  color: #fff;
  transition: 0.3s ease-in;
}
table.company_table .btn a:hover {
  background: #fff;
  color: #e75f9f;
  transition: 0.3s ease-in;
}

.companypage__access {
  padding: 40px 20px 0;
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (min-width: 641px) {
  .companypage__access {
    padding: 40px 30px 0;
  }
}
@media only screen and (min-width: 1024px) {
  .companypage__access {
    padding: 80px 30px 0;
  }
}
.companypage__access h2 {
  width: 100%;
}
.companypage__access .map {
  width: 100%;
}
.companypage__access p {
  font-size: 14px;
  width: 100%;
  font-weight: 500;
}
@media only screen and (min-width: 768px) {
  .companypage__access p {
    width: 50%;
  }
}

.articlespage__single__sharedbutton li.twitter a {
  background: url(../img/foundation/interview/X.svg) no-repeat center/cover;
}

#toc_container {
  background: #f9f8f8;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 25px 23px;
  margin-bottom: 40px;
}
#toc_container .toc_title {
  color: #106ab5;
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 10px;
  line-height: 1;
}
#toc_container .toc_list {
  padding-left: 1em;
}
#toc_container .toc_list li {
  font-size: 14px;
  font-weight: 500;
}
#toc_container .toc_list li a {
  transition: 0.3s all;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}
#toc_container .toc_list li a:hover {
  transition: 0.3s all;
  border-bottom: 1px solid #2a3b4c;
}
#toc_container .toc_list span {
  display: none;
}
#toc_container .toc_list ul {
  padding-left: 1.5em;
}

.mokuji {
  display: none;
}
@media only screen and (min-width: 769px) {
  .mokuji {
    display: block;
    background: #f9f8f8;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    padding: 25px 23px;
    margin-bottom: 40px;
  }
  .mokuji > div > h3 {
    color: #106ab5;
    font-size: 15px;
    font-weight: 700;
  }
  .mokuji .toc_widget_list {
    padding-left: 1em;
  }
  .mokuji .toc_widget_list li {
    font-size: 14px;
    font-weight: 500;
  }
  .mokuji .toc_widget_list li a {
    transition: 0.3s ease-in;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }
  .mokuji .toc_widget_list li a:hover {
    transition: 0.3s ease-in;
    border-bottom: 1px solid #2a3b4c;
  }
  .mokuji .toc_widget_list span {
    display: none;
  }
  .mokuji .toc_widget_list ul {
    padding-left: 1.5em;
  }
}

@media only screen and (min-width: 1024px) {
  .articlespage__single__entry h2 span::before,
.articlespage__single__entry h3 span::before {
    content: "";
    display: block;
    margin-top: -150px;
    padding-top: 150px;
  }
}

.articlespage__single__item .body .category {
  display: inline-block;
  background: #ebf4ff;
  font-size: 12px;
  border-radius: 100vw;
  padding: 0 1em;
  color: #106ab5;
  font-weight: 500;
}
.articlespage__single__item .body .date {
  color: #888888;
  font-size: 12px;
  text-align: left;
  margin: 0.6em 0 0 0.5em;
}
.articlespage__single__item .body h3 {
  font-weight: 700;
}

.workspage__container .pager li .text {
  background: #fff;
  border: 1px solid #d8e3ee;
  color: #2a3b4c;
}

.interviewpage__section {
  padding: 0 20px;
}
@media only screen and (min-width: 1200px) {
  .interviewpage__section {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) {
  .interviewpage__list {
    justify-content: flex-start;
    column-gap: 2%;
  }
}

@media only screen and (min-width: 641px) {
  .interviewpage__item {
    width: 32%;
    margin-right: 0;
    min-width: auto;
  }
}

.inpage__header .icon::before {
  content: " ";
  width: 28px;
  height: 24px;
  background: url("../img/foundation/service/header__icon-neith.svg") no-repeat center/cover;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-right: 5px;
}

.recruitpage__container .inpage__header .wrap {
  height: auto;
}
@media only screen and (min-width: 640px) {
  .recruitpage__container .inpage__header .wrap {
    height: 100%;
  }
}

.recruitpage__message {
  padding: 0 20px;
}
.recruitpage__message h2 {
  margin-bottom: 20px;
  font-size: 24px;
}
@media only screen and (min-width: 768px) {
  .recruitpage__message h2 {
    margin-bottom: 30px;
    font-size: 32px;
  }
}
@media only screen and (min-width: 1180px) {
  .recruitpage__message h2 {
    margin-bottom: 40px;
    font-size: 40px;
  }
}
.recruitpage__message .copy {
  font-weight: 500;
  letter-spacing: 0.03em;
  line-height: 32px;
  text-align: center;
  margin-bottom: 40px;
}
@media only screen and (min-width: 768px) {
  .recruitpage__message .copy {
    margin-bottom: 52px;
  }
}
.recruitpage__message h3 {
  color: #106ab5;
  line-height: 47.2px;
  font-weight: bold;
  letter-spacing: 0.03em;
  text-align: center;
  margin-bottom: 10px;
  font-size: 24px;
}
@media only screen and (min-width: 768px) {
  .recruitpage__message h3 {
    font-size: 32px;
    line-height: 47.2px;
  }
}
@media only screen and (min-width: 1180px) {
  .recruitpage__message h3 {
    font-size: 40px;
    line-height: 58px;
    margin-bottom: 28px;
  }
}

.recruitpage__mainimage {
  margin-bottom: 40px;
}
.recruitpage__mainimage img {
  overflow: hidden;
  border-radius: 12px;
}
@media only screen and (min-width: 768px) {
  .recruitpage__mainimage img {
    border-radius: 22px;
  }
}
@media only screen and (min-width: 768px) {
  .recruitpage__mainimage {
    margin-bottom: 80px;
  }
}

.toppage__download__list {
  margin-bottom: 0;
  flex-wrap: wrap;
}
@media only screen and (min-width: 640px) {
  .toppage__download__list {
    row-gap: 20px;
    column-gap: 4%;
  }
}
@media only screen and (min-width: 768px) {
  .toppage__download__list {
    row-gap: 50px;
    column-gap: 2%;
  }
}

.toppage__download__item {
  background: #fff;
  border: 1px solid #d8e3ee;
  border-radius: 14px;
  padding: 20px 24px;
  margin-right: 0;
}
@media only screen and (min-width: 640px) {
  .toppage__download__item {
    width: 48%;
  }
}
@media only screen and (min-width: 768px) {
  .toppage__download__item {
    width: 32%;
    padding: 30px 40px;
  }
}
@media only screen and (min-width: 1180px) {
  .toppage__download__item {
    min-height: 365px;
  }
}
.toppage__download__item .img {
  text-align: center;
  margin-bottom: 20px;
}
@media only screen and (min-width: 768px) {
  .toppage__download__item .img {
    margin-bottom: 29px;
  }
}
.toppage__download__item h3 {
  text-align: center;
  color: #106ab5;
  font-size: 18px;
  margin-bottom: 12px;
}
@media only screen and (min-width: 768px) {
  .toppage__download__item h3 {
    font-size: 24px;
    line-height: 31px;
  }
}
@media only screen and (min-width: 1180px) {
  .toppage__download__item:nth-of-type(-n + 3) h3 {
    min-height: 62px;
  }
}
.toppage__download__item p {
  font-weight: 500;
}

.recruitpage__office {
  padding: 40px 0 50px;
}
@media only screen and (min-width: 768px) {
  .recruitpage__office {
    padding: 80px 0 90px;
  }
}
@media only screen and (min-width: 768px) {
  .recruitpage__office h2 {
    margin-bottom: 50px;
  }
}
@media only screen and (min-width: 1180px) {
  .recruitpage__office h2 {
    margin-bottom: 72px;
  }
}

.recruitpage__office__list {
  justify-content: center;
  padding: 0 20px;
}
@media only screen and (min-width: 768px) {
  .recruitpage__office__list {
    column-gap: 2%;
    row-gap: 30px;
  }
}
@media only screen and (min-width: 1180px) {
  .recruitpage__office__list {
    row-gap: 68px;
  }
}

.recruitpage__office__item {
  border: none;
  padding: 0;
}
@media only screen and (min-width: 768px) {
  .recruitpage__office__item {
    width: 32%;
  }
}
@media only screen and (min-width: 1180px) {
  .recruitpage__office__item {
    min-height: 100%;
  }
}
.recruitpage__office__item img {
  border-radius: 17px;
  overflow: hidden;
  width: 100%;
}

.recruitpage__system h2 {
  margin-bottom: 20px;
}
@media only screen and (min-width: 768px) {
  .recruitpage__system h2 {
    margin-bottom: 50px;
  }
}
@media only screen and (min-width: 1180px) {
  .recruitpage__system h2 {
    margin-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) {
  .recruitpage__joblist {
    max-width: 1180px;
    width: 100%;
    margin: 0 auto;
  }
}
.recruitpage__joblist h2 {
  margin-bottom: 30px;
}
@media only screen and (min-width: 768px) {
  .recruitpage__joblist h2 {
    margin-bottom: 50px;
  }
}
@media only screen and (min-width: 1180px) {
  .recruitpage__joblist h2 {
    margin-bottom: 70px;
  }
}
.recruitpage__joblist h3 {
  padding: 0;
}

.recruitpage__joblist__list {
  justify-content: space-between;
}
@media only screen and (min-width: 768px) {
  .recruitpage__joblist__list {
    max-width: 1180px;
  }
}

.recruitpage__joblist__item {
  background: none;
  border: none;
  padding: 0;
}
@media only screen and (min-width: 768px) {
  .recruitpage__joblist__item {
    width: 46%;
  }
}
@media only screen and (min-width: 1180px) {
  .recruitpage__joblist__item {
    width: 30.5%;
    min-height: auto;
  }
}
.recruitpage__joblist__item img {
  display: block;
  margin: 0 auto 15px;
}
@media only screen and (min-width: 768px) {
  .recruitpage__joblist__item img {
    height: 238px;
  }
}
.recruitpage__joblist__item h3,
.recruitpage__joblist__item p {
  text-align: left;
  word-break: break-all;
}
.recruitpage__joblist__item h3 {
  margin-bottom: 12px;
  line-height: 1;
  font-size: 16px;
}
@media only screen and (min-width: 768px) {
  .recruitpage__joblist__item h3 {
    margin-bottom: 22px;
    font-size: 20px;
    min-height: 29px !important;
  }
}
.recruitpage__joblist__item p {
  letter-spacing: 0.03em;
}

.recruitpage__requirements {
  margin-top: 40px;
}
@media only screen and (min-width: 768px) {
  .recruitpage__requirements {
    margin-top: 60px;
  }
}
@media only screen and (min-width: 1180px) {
  .recruitpage__requirements {
    margin-top: 80px;
  }
}

@media only screen and (min-width: 768px) {
  .recruitpage__application__body h2 {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 1180px) {
  .recruitpage__application__body h2 {
    margin-bottom: 40px;
  }
}

.recruitpage__application__table {
  background: #fff;
  border: 1px solid #d8e3ee;
  border-radius: 14px;
  padding: 24px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.04);
  margin-bottom: 20px;
}
@media only screen and (min-width: 768px) {
  .recruitpage__application__table {
    border-radius: 20px;
    padding: 40px;
    margin-bottom: 40px;
  }
}
.recruitpage__application__table table {
  margin-bottom: 0;
}
.recruitpage__application__table table th,
.recruitpage__application__table table td {
  font-size: 14px;
}
@media only screen and (min-width: 768px) {
  .recruitpage__application__table table th,
.recruitpage__application__table table td {
    font-size: 16px;
  }
}

.footer__reservation02 {
  background: url(../img/commons/footer/contact/bg.png) center center no-repeat #106ab5;
  background-size: cover;
}
.footer__reservation02 h3 {
  color: #fff;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
  letter-spacing: 0.03em;
  text-align: center;
  margin-bottom: 20px;
}
@media only screen and (min-width: 640px) {
  .footer__reservation02 h3 {
    font-size: 24px;
    line-height: 35px;
  }
}
@media only screen and (min-width: 768px) {
  .footer__reservation02 h3 {
    font-size: 32px;
    line-height: 46px;
    margin-bottom: 15px;
    margin-bottom: 40px;
  }
}
@media only screen and (min-width: 1180px) {
  .footer__reservation02 h3 {
    font-size: 40px;
    line-height: 58px;
    margin-bottom: 15px;
    margin-bottom: 40px;
  }
}

.footer__reservation__wrap {
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;
  justify-content: center;
}
@media only screen and (min-width: 640px) {
  .footer__reservation__wrap {
    column-gap: 20px;
  }
}
@media only screen and (min-width: 1180px) {
  .footer__reservation__wrap {
    column-gap: 40px;
  }
}

.footer__reservation__btn {
  width: 100%;
}
@media only screen and (min-width: 640px) {
  .footer__reservation__btn {
    width: 48%;
    max-width: 480px;
  }
}
.footer__reservation__btn a {
  display: block;
  border-radius: 100vw;
  padding: 0.8em;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  max-width: 480px;
  margin: 0 auto;
}
@media only screen and (min-width: 768px) {
  .footer__reservation__btn a {
    font-size: 24px;
  }
}
@media only screen and (min-width: 1180px) {
  .footer__reservation__btn a {
    font-size: 30px;
  }
}
.footer__reservation__btn p {
  text-align: center;
  color: #fff;
  font-size: 14px;
  margin-top: 1em;
}
@media only screen and (min-width: 768px) {
  .footer__reservation__btn p {
    font-size: 16px;
  }
}

a.footer__reservation__contact {
  transition: 0.5s all;
  color: #fff;
  background: url(../img/commons/footer/contact/ico_mail.svg) left 30px center no-repeat #e75f9f;
  background-size: 27px 22px;
  border: 2px solid #e75f9f;
}
@media only screen and (min-width: 768px) {
  a.footer__reservation__contact {
    background: url(../img/commons/footer/contact/ico_mail.svg) left 40px center no-repeat #e75f9f;
    background-size: 30px 24px;
  }
}
@media only screen and (min-width: 1180px) {
  a.footer__reservation__contact {
    background: url(../img/commons/footer/contact/ico_mail.svg) left 54px center no-repeat #e75f9f;
    background-size: 36px 29px;
  }
}
a.footer__reservation__contact:hover {
  opacity: 1;
  transition: 0.5s all;
  background-color: #ccc;
  color: #e75f9f;
  background: url(../img/commons/footer/contact/ico_mail_pink.svg) left 30px center no-repeat #fff;
  background-size: 27px 22px;
}
@media only screen and (min-width: 768px) {
  a.footer__reservation__contact:hover {
    background: url(../img/commons/footer/contact/ico_mail_pink.svg) left 40px center no-repeat #fff;
    background-size: 30px 24px;
  }
}
@media only screen and (min-width: 1180px) {
  a.footer__reservation__contact:hover {
    background: url(../img/commons/footer/contact/ico_mail_pink.svg) left 54px center no-repeat #fff;
    background-size: 36px 29px;
  }
}

a.footer__reservation__download {
  transition: 0.5s all;
  color: #2a3b4c;
  background: url(../img/commons/footer/contact/ico_document.svg) left 30px center no-repeat #fff;
  background-size: 27px 19px;
  border: 2px solid #fff;
}
@media only screen and (min-width: 768px) {
  a.footer__reservation__download {
    background: url(../img/commons/footer/contact/ico_document.svg) left 40px center no-repeat #fff;
    background-size: 34px 24px;
    padding-left: 2em;
  }
}
@media only screen and (min-width: 1180px) {
  a.footer__reservation__download {
    background: url(../img/commons/footer/contact/ico_document.svg) left 52px center no-repeat #fff;
    background-size: 43px 30px;
  }
}
a.footer__reservation__download:hover {
  opacity: 1;
  transition: 0.5s all;
  color: #fff;
  background: url(../img/commons/footer/contact/ico_document_white.svg) left 30px center no-repeat #2a3b4c;
  background-size: 27px 19px;
}
@media only screen and (min-width: 768px) {
  a.footer__reservation__download:hover {
    background: url(../img/commons/footer/contact/ico_document_white.svg) left 40px center no-repeat #2a3b4c;
    background-size: 34px 24px;
  }
}
@media only screen and (min-width: 1180px) {
  a.footer__reservation__download:hover {
    background: url(../img/commons/footer/contact/ico_document_white.svg) left 52px center no-repeat #2a3b4c;
    background-size: 43px 30px;
  }
}