.companypage .inpage__header .photo {
  margin: auto 41px auto 0;
}

.companypage__container {
  .inpage__header {
    margin-bottom: 0;
  }
}

.companypage__main {
  background: url(../img/foundation/company/bg__photo.jpg) top right 5.2% no-repeat;
  background-size: 32.7vw 29.8vw;
  padding: 40px 20px;
  position: relative;
  @media only screen and (min-width: 1024px) {
    padding-top: 80px;
  }
}

.companypage__main__head {
  @media only screen and (min-width: 1024px) {
    max-width: 1180px;
    margin: 0 auto;
  }
  h2 {
    line-height: 1.45;
    text-align: left;
  }

  p {
    font-size: min(4vw, 18px);
    margin-top: 30px;
    @media only screen and (min-width: 1024px) {
      margin-top: 60px;
    }
    &:last-of-type {
      margin-top: 20px;
      @media only screen and (min-width: 1024px) {
        margin-top: 40px;
      }
    }
  }
}

.companypage__main__bg1 {
  background: url(../img/foundation/company/bg__img1.svg) center center no-repeat;
  background-size: contain;
  width: 17.6vw;
  height: 14.42vw;
  position: absolute;
  top: 33.3%;
  left: calc(50% - 31.56%);
}

.companypage__main__bg2 {
  background: url(../img/foundation/company/bg__img2.svg) center center no-repeat;
  background-size: contain;
  width: 25vw;
  height: 20.52vw;
  position: absolute;
  top: 46.22%;
  left: calc(50% + 11.35%);
}

.companypage__main__item {
  max-width: 853px;
  margin: 35px auto 0;
  @media only screen and (min-width: 1024px) {
    margin-top: 95px;
  }

  h2 {
    font-weight: 400;
    font-size: min(6.4vw, 56px);
    border-bottom: 1px solid #d8e3ee;
    padding-bottom: 10px;
    text-align: left;
    @media only screen and (min-width: 1024px) {
      padding-bottom: 20px;
    }
  }

  h3 {
    margin-top: 20px;
    font-size: min(4.8vw, 24px);
    font-weight: 700;
    @media only screen and (min-width: 1024px) {
      margin-top: 40px;
    }
  }

  p {
    margin-top: 15px;
    @media only screen and (min-width: 1024px) {
      margin-top: 25px;
    }
  }
}

.companypage__main__expert {
  padding: 40px 20px;
  @media only screen and (min-width: 641px) {
    padding: 40px 30px;
  }
  @media only screen and (min-width: 1024px) {
    padding: 95px 30px 80px;
    max-width: 1240px;
    margin: 0 auto;
  }
}

.companypage__expert__list {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  border: 1px solid #d8e3ee;
  border-radius: 14px;
  box-shadow: 0px 10px 20px rgba($color: #000000, $alpha: 0.04);
  margin-top: 30px;
  @media only screen and (min-width: 1024px) {
    margin-top: 40px;
  }
}

.companypage__expert__item {
  border-bottom: 1px solid #d8e3ee;
  padding: 30px 25px;
  width: 100%;
  @media only screen and (min-width: 641px) {
    width: 50%;
  }
  @media only screen and (min-width: 1024px) {
    padding: 40px;
    border-bottom: none;
    border-right: 1px solid #d8e3ee;
    width: 25%;
  }
  &:last-of-type {
    border-bottom: none;
    @media only screen and (min-width: 1024px) {
      border-right: none;
    }
  }
  &:nth-of-type(odd) {
    @media only screen and (min-width: 641px) {
      border-right: 1px solid #d8e3ee;
    }
  }
  .photo {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 113px;
  }

  .body {
    margin-top: 20px;
    @media only screen and (min-width: 1024px) {
      margin-top: 25px;
    }

    p {
      text-align: center;
      font-weight: 500;
    }
  }
}

.companypage__about {
  padding: 40px 20px;
  @media only screen and (min-width: 641px) {
    padding: 40px 30px;
  }
  @media only screen and (min-width: 1024px) {
    padding: 80px 30px;
  }
}

.companypage__about__info {
  background: #fff;
  border: 1px solid #d8e3ee;
  border-radius: 14px;
  box-shadow: 0px 10px 20px rgba($color: #000000, $alpha: 0.04);
  margin-top: 30px;
  padding: 20px;
  @media only screen and (min-width: 1024px) {
    margin-top: 40px;
    padding: 40px;
  }
}

table.company_table {
  border-top: 1px solid #d8e3ee;
  width: 100%;
  th,
  td {
    font-weight: 500;
    border-bottom: 1px solid #d8e3ee;
    padding: 20px 0 30px;
    font-size: min(3.467vw, 16px);
    word-break: break-all;
    vertical-align: middle;
    @media only screen and (min-width: 1024px) {
      padding: 30px 0 40px;
    }
  }

  th {
    width: 26%;
    padding-right: 1em;
  }
  td {
    width: 74%;
  }
  .btn {
    margin-top: 10px;
    @media only screen and (min-width: 768px) {
      display: inline-block;
      margin-left: 50px;
    }
    a {
      background: #e75f9f;
      border: 2px solid #e75f9f;
      border-radius: 100vw;
      display: inline-block;
      padding: 0.5em 3em;
      color: #fff;
      transition: 0.3s ease-in;

      &:hover {
        background: #fff;
        color: #e75f9f;
        transition: 0.3s ease-in;
      }
    }
  }
}

.companypage__access {
  padding: 40px 20px 0;
  @media only screen and (min-width: 641px) {
    padding: 40px 30px 0;
  }
  @media only screen and (min-width: 1024px) {
    padding: 80px 30px 0;
  }

  display: flex;
  flex-wrap: wrap;
  h2 {
    width: 100%;
  }

  .map {
    width: 100%;
  }

  p {
    font-size: 14px;
    width: 100%;
    font-weight: 500;
    @media only screen and (min-width: 768px) {
      width: 50%;
    }
  }
}
