@use "modules";
@use "page";

.inpage__header .icon::before {
  content: " ";
  width: 28px;
  height: 24px;
  background: url("../img/foundation/service/header__icon-neith.svg") no-repeat center/cover;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-right: 5px;
}

.recruitpage__container {
  .inpage__header {
    .wrap {
      height: auto;
      @media only screen and (min-width: 640px) {
        height: 100%;
      }
    }
  }
}
.recruitpage__message {
  padding: 0 20px;

  h2 {
    margin-bottom: 20px;
    font-size: 24px;
    @media only screen and (min-width: 768px) {
      margin-bottom: 30px;
      font-size: 32px;
    }
    @media only screen and (min-width: 1180px) {
      margin-bottom: 40px;
      font-size: 40px;
    }
  }
  .copy {
    font-weight: 500;
    letter-spacing: 0.03em;
    line-height: 32px;
    text-align: center;
    margin-bottom: 40px;
    @media only screen and (min-width: 768px) {
      margin-bottom: 52px;
    }
  }
  h3 {
    color: #106ab5;
    line-height: 47.2px;
    font-weight: bold;
    letter-spacing: 0.03em;
    text-align: center;
    margin-bottom: 10px;
    font-size: 24px;
    @media only screen and (min-width: 768px) {
      font-size: 32px;
      line-height: 47.2px;
    }
    @media only screen and (min-width: 1180px) {
      font-size: 40px;
      line-height: 58px;
      margin-bottom: 28px;
    }
  }
}

.recruitpage__mainimage {
  img {
    overflow: hidden;
    border-radius: 12px;
    @media only screen and (min-width: 768px) {
      border-radius: 22px;
    }
  }
  margin-bottom: 40px;
  @media only screen and (min-width: 768px) {
    margin-bottom: 80px;
  }
}

.toppage__download__list {
  margin-bottom: 0;
  flex-wrap: wrap;
  @media only screen and (min-width: 640px) {
    row-gap: 20px;
    column-gap: 4%;
  }
  @media only screen and (min-width: 768px) {
    row-gap: 50px;
    column-gap: 2%;
  }
}

.toppage__download__item {
  background: #fff;
  border: 1px solid #d8e3ee;
  border-radius: 14px;
  padding: 20px 24px;
  margin-right: 0;
  @media only screen and (min-width: 640px) {
    width: 48%;
  }
  @media only screen and (min-width: 768px) {
    width: 32%;
    padding: 30px 40px;
  }
  @media only screen and (min-width: 1180px) {
    min-height: 365px;
  }

  .img {
    text-align: center;
    margin-bottom: 20px;
    @media only screen and (min-width: 768px) {
      margin-bottom: 29px;
    }
  }

  h3 {
    text-align: center;
    color: #106ab5;
    font-size: 18px;
    margin-bottom: 12px;
    @media only screen and (min-width: 768px) {
      font-size: 24px;
      line-height: 31px;
    }
  }
  @media only screen and (min-width: 1180px) {
    &:nth-of-type(-n + 3) {
      h3 {
        min-height: 62px;
      }
    }
  }

  p {
    font-weight: 500;
  }
}

.recruitpage__office {
  padding: 40px 0 50px;
  @media only screen and (min-width: 768px) {
    padding: 80px 0 90px;
  }
  h2 {
    @media only screen and (min-width: 768px) {
      margin-bottom: 50px;
    }
    @media only screen and (min-width: 1180px) {
      margin-bottom: 72px;
    }
  }
}

.recruitpage__office__list {
  justify-content: center;
  padding: 0 20px;
  @media only screen and (min-width: 768px) {
    column-gap: 2%;
    row-gap: 30px;
  }
  @media only screen and (min-width: 1180px) {
    row-gap: 68px;
  }
}

.recruitpage__office__item {
  border: none;
  padding: 0;
  @media only screen and (min-width: 768px) {
    width: 32%;
  }
  @media only screen and (min-width: 1180px) {
    min-height: 100%;
  }

  img {
    border-radius: 17px;
    overflow: hidden;
    width: 100%;
  }
}

.recruitpage__system {
  h2 {
    margin-bottom: 20px;
    @media only screen and (min-width: 768px) {
      margin-bottom: 50px;
    }
    @media only screen and (min-width: 1180px) {
      margin-bottom: 100px;
    }
  }
}

.recruitpage__joblist {
  @media only screen and (min-width: 768px) {
    max-width: 1180px;
    width: 100%;
    margin: 0 auto;
  }
  h2 {
    margin-bottom: 30px;
    @media only screen and (min-width: 768px) {
      margin-bottom: 50px;
    }
    @media only screen and (min-width: 1180px) {
      margin-bottom: 70px;
    }
  }

  h3 {
    padding: 0;
  }
}

.recruitpage__joblist__list {
  justify-content: space-between;
  @media only screen and (min-width: 768px) {
    max-width: 1180px;
  }
}
.recruitpage__joblist__item {
  background: none;
  border: none;
  padding: 0;
  @media only screen and (min-width: 768px) {
    width: 46%;
  }
  @media only screen and (min-width: 1180px) {
    width: 30.5%;
    min-height: auto;
  }

  img {
    display: block;
    margin: 0 auto 15px;
    @media only screen and (min-width: 768px) {
      height: 238px;
    }
  }

  h3,
  p {
    text-align: left;
    //text-align: justify;
    word-break: break-all;
  }

  h3 {
    margin-bottom: 12px;
    line-height: 1;
    font-size: 16px;
    @media only screen and (min-width: 768px) {
      margin-bottom: 22px;
      font-size: 20px;
      min-height: 29px !important;
    }
  }

  p {
    letter-spacing: 0.03em;
  }
}

.recruitpage__requirements {
  margin-top: 40px;
  @media only screen and (min-width: 768px) {
    margin-top: 60px;
  }
  @media only screen and (min-width: 1180px) {
    margin-top: 80px;
  }
}

.recruitpage__application__body {
  h2 {
    @media only screen and (min-width: 768px) {
      margin-bottom: 20px;
    }
    @media only screen and (min-width: 1180px) {
      margin-bottom: 40px;
    }
  }
}

.recruitpage__application__table {
  background: #fff;
  border: 1px solid #d8e3ee;
  border-radius: 14px;
  padding: 24px;
  box-shadow: 0 10px 20px rgba($color: #000000, $alpha: 0.04);
  margin-bottom: 20px;
  @media only screen and (min-width: 768px) {
    border-radius: 20px;
    padding: 40px;
    margin-bottom: 40px;
  }

  table {
    margin-bottom: 0;
    th,
    td {
      font-size: 14px;
      @media only screen and (min-width: 768px) {
        font-size: 16px;
      }
    }
  }
}

.footer__reservation02 {
  background: url(../img/commons/footer/contact/bg.png) center center no-repeat #106ab5;
  background-size: cover;

  h3 {
    color: #fff;
    font-size: 18px;
    line-height: 26px;
    font-weight: bold;
    letter-spacing: 0.03em;
    text-align: center;
    margin-bottom: 20px;
    @media only screen and (min-width: 640px) {
      font-size: 24px;
      line-height: 35px;
    }
    @media only screen and (min-width: 768px) {
      font-size: 32px;
      line-height: 46px;
      margin-bottom: 15px;
      margin-bottom: 40px;
    }
    @media only screen and (min-width: 1180px) {
      font-size: 40px;
      line-height: 58px;
      margin-bottom: 15px;
      margin-bottom: 40px;
    }
  }
}

.footer__reservation__wrap {
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;
  justify-content: center;
  @media only screen and (min-width: 640px) {
    column-gap: 20px;
  }
  @media only screen and (min-width: 1180px) {
    column-gap: 40px;
  }
}

.footer__reservation__btn {
  width: 100%;
  @media only screen and (min-width: 640px) {
    width: 48%;
    max-width: 480px;
  }

  a {
    display: block;
    border-radius: 100vw;
    padding: 0.8em;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    max-width: 480px;
    margin: 0 auto;
    @media only screen and (min-width: 768px) {
      font-size: 24px;
    }
    @media only screen and (min-width: 1180px) {
      font-size: 30px;
    }
  }

  p {
    text-align: center;
    color: #fff;
    font-size: 14px;
    margin-top: 1em;
    @media only screen and (min-width: 768px) {
      font-size: 16px;
    }
  }
}

a.footer__reservation__contact {
  transition: 0.5s all;
  color: #fff;
  background: url(../img/commons/footer/contact/ico_mail.svg) left 30px center no-repeat #e75f9f;
  background-size: 27px 22px;
  border: 2px solid #e75f9f;
  @media only screen and (min-width: 768px) {
    background: url(../img/commons/footer/contact/ico_mail.svg) left 40px center no-repeat #e75f9f;
    background-size: 30px 24px;
  }
  @media only screen and (min-width: 1180px) {
    background: url(../img/commons/footer/contact/ico_mail.svg) left 54px center no-repeat #e75f9f;
    background-size: 36px 29px;
  }
  &:hover {
    opacity: 1;
    transition: 0.5s all;
    background-color: #ccc;
    color: #e75f9f;
    background: url(../img/commons/footer/contact/ico_mail_pink.svg) left 30px center no-repeat #fff;
    background-size: 27px 22px;
    @media only screen and (min-width: 768px) {
      background: url(../img/commons/footer/contact/ico_mail_pink.svg) left 40px center no-repeat #fff;
      background-size: 30px 24px;
    }
    @media only screen and (min-width: 1180px) {
      background: url(../img/commons/footer/contact/ico_mail_pink.svg) left 54px center no-repeat #fff;
      background-size: 36px 29px;
    }
  }
}

a.footer__reservation__download {
  transition: 0.5s all;
  color: #2a3b4c;
  background: url(../img/commons/footer/contact/ico_document.svg) left 30px center no-repeat #fff;
  background-size: 27px 19px;
  border: 2px solid #fff;
  @media only screen and (min-width: 768px) {
    background: url(../img/commons/footer/contact/ico_document.svg) left 40px center no-repeat #fff;
    background-size: 34px 24px;
    padding-left: 2em;
  }
  @media only screen and (min-width: 1180px) {
    background: url(../img/commons/footer/contact/ico_document.svg) left 52px center no-repeat #fff;
    background-size: 43px 30px;
  }
  &:hover {
    opacity: 1;
    transition: 0.5s all;
    color: #fff;
    background: url(../img/commons/footer/contact/ico_document_white.svg) left 30px center no-repeat #2a3b4c;
    background-size: 27px 19px;
    @media only screen and (min-width: 768px) {
      background: url(../img/commons/footer/contact/ico_document_white.svg) left 40px center no-repeat #2a3b4c;
      background-size: 34px 24px;
    }
    @media only screen and (min-width: 1180px) {
      background: url(../img/commons/footer/contact/ico_document_white.svg) left 52px center no-repeat #2a3b4c;
      background-size: 43px 30px;
    }
  }
}
