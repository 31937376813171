@use "../mixin/index" as *;

.servicepage__about__item {
  .body {
    @media only screen and (min-width: 769px) {
      width: calc(100% - 32.2%);
    }
    @media only screen and (min-width: 1024px) {
      width: calc(100% - 440px);
      padding-left: 10.5%;
    }
  }

  .photo {
    @media only screen and (min-width: 769px) {
      //width: 32.2% !important;
      min-width: 32.2% !important;
    }
    @media only screen and (min-width: 1024px) {
      min-width: 440px !important;
      margin: 0 !important;
    }
  }
}

.servicepage__point {
  background: linear-gradient(90deg, #f4f7fa 0%, #f4f7fa 50%, #ffffff 51%, #ffffff 100%);
}

.servicepage__point__body {
  background: #f4f7fa;
  width: calc(100% - 40px);
  margin: 0 auto;
  padding: 30px 30px 30px 10px;
  @media only screen and (min-width: 769px) {
    padding: 50px 40px 50px 20px;
  }
  @media only screen and (min-width: 1024px) {
    padding: 80px 90px;
    max-width: 1180px;
  }
}

.servicepage__point__item {
  margin-top: 30px;
  @media only screen and (min-width: 769px) {
    display: flex;
    margin-top: 50px;
  }
  @media only screen and (min-width: 1024px) {
    margin-top: 80px;
  }

  .body {
    @media only screen and (min-width: 769px) {
      width: calc(100% - 37%);
      padding-left: 30px;
    }
    @media only screen and (min-width: 1024px) {
      padding-left: 60px;
    }

    p.point-label {
      margin-top: 10px;
      color: #e75f9f;
      font-weight: 700;
      @media only screen and (min-width: 769px) {
        margin-top: 0;
      }
      @media only screen and (min-width: 1024px) {
        font-size: 15px;
      }
      span {
        font-size: 1.5em;
        @media only screen and (min-width: 1024px) {
          font-size: 20px;
        }
      }
    }

    h3 {
      font-size: min(4.8vw, 24px);
      font-weight: 700;
    }

    p {
      margin-top: 20px;
      font-weight: 500;
      @media only screen and (min-width: 1024px) {
        margin-top: 40px;
      }
    }
  }

  .photo {
    @media only screen and (min-width: 769px) {
      width: 37%;
      max-width: 370px;
    }
  }

  &:nth-of-type(2) {
    @media only screen and (min-width: 769px) {
      .photo {
        order: 2;
      }
      .body {
        order: 1;
        padding-left: 0;
        padding-right: 30px;
      }
    }
    @media only screen and (min-width: 1024px) {
      .body {
        padding-right: 60px;
      }
    }
  }
}

.homepage-point {
  @media only screen and (min-width: 769px) {
    padding-bottom: 295px;
  }
}

.servicepage__media {
  width: calc(100% - 40px);
  margin: 30px auto 0;
  @media only screen and (min-width: 769px) {
    margin-top: -230px;
    width: calc(100% - 80px);
    max-width: 800px;
  }
}

.servicepage__media__wrap {
  border: 1px solid #d8e3ee;
  background: #fff;
  border-radius: 14px;
  margin-top: 20px;
  @media only screen and (min-width: 769px) {
    display: flex;
    border-radius: 20px;
  }
}

.servicepage__media__item {
  padding: 30px;
  text-align: center;
  @media only screen and (min-width: 641px) {
    width: 50%;
    padding: 40px;
  }

  &:nth-of-type(1) {
    border-bottom: 1px solid #d8e3ee;
    @media only screen and (min-width: 641px) {
      border-bottom: none;
      border-right: 1px solid #d8e3ee;
    }

    .photo {
      min-height: 148px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .photo {
    text-align: center;
  }

  .body {
    p {
      padding-top: 20px;
      text-align: center;
      font-weight: 500;
      @media only screen and (min-width: 641px) {
        font-size: min(2.496vw, 19px);
      }
    }
  }
}

.servicepage__analysis,
.servicepage__support {
  margin-top: 50px;
  @media only screen and (min-width: 769px) {
    margin-top: 70px;
  }
  @media only screen and (min-width: 1024px) {
    margin-top: 90px;
  }
}

.servicepage__analysis__item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: calc(100% - 40px);
  margin: 30px auto 0;
  @media only screen and (min-width: 769px) {
    width: calc(100% - 60px);
    //max-width: 1240px;
  }
  @media only screen and (min-width: 1240px) {
    width: 100%;
  }

  .photo {
    width: 80%;
    max-width: 440px;
    margin: 0 auto;
    @media only screen and (min-width: 641px) {
      width: 37.28%;
    }
  }
  .body {
    width: 100%;
    margin-top: 30px;
    @media only screen and (min-width: 641px) {
      width: 62.72%;
      padding-left: 40px;
    }
    @media only screen and (min-width: 1024px) {
      padding-left: 10.5%;
    }

    h3 {
      font-size: min(4.8vw, 24px);
      font-weight: 700;
    }

    p {
      font-size: 14px;
      margin-top: 20px;
      @media only screen and (min-width: 769px) {
        margin-top: 30px;
      }
    }
  }
}

.servicepage__support {
  margin-bottom: 50px;
  max-width: 1000px;
  @media only screen and (min-width: 1240px) {
    margin-bottom: 100px;
  }
}
.servicepage__support__wrap {
  width: calc(100% - 40px);
  margin: 30px auto 0;
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;
  justify-content: space-between;
  @media only screen and (min-width: 769px) {
    width: calc(100% - 60px);
  }
  @media only screen and (min-width: 1240px) {
    width: 100%;
    margin-top: 40px;
  }
}

.servicepage__support__item {
  background: #fff;
  border: 1px solid #d8e3ee;
  border-radius: 14px;
  box-shadow: 0px 10px 20px rgba($color: #000000, $alpha: 0.04);
  padding: 30px 25px;
  @media only screen and (min-width: 641px) {
    width: 48%;
  }
  @media only screen and (min-width: 769px) {
    border-radius: 20px;
  }
  @media only screen and (min-width: 1024px) {
    width: 23.5%;
  }

  h3 {
    text-align: center;
    font-size: min(4.533vw, 20px);
    font-weight: 700;
  }

  p {
    margin-top: 15px;
    font-size: 14px;
    font-weight: 500;
  }

  .btn {
    text-align: center;
    width: auto;
    padding: 0.5em 1.5em;
    height: auto;
    margin: 20px auto 0;
  }
}

.servicepage__site_list {
  padding: 40px 20px;
  @media only screen and (min-width: 769px) {
    padding: 50px 30px;
  }
  @media only screen and (min-width: 1024px) {
    padding: 80px 30px;
  }
}

.servicepage__partner {
  background: none;
  h2 {
    @media only screen and (min-width: 769px) {
      font-size: 30px;
    }
  }
}

.servicepage__item__wrap {
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;
  //justify-content: space-between;
  margin-top: 20px;
  @media only screen and (min-width: 641px) {
    column-gap: 4%;
  }
  @media only screen and (min-width: 769px) {
    column-gap: 4.25%;
    margin-top: 40px;
  }
}

.servicepage__item {
  background: #fff;
  border: 1px solid #d8e3ee;
  border-radius: 14px;
  box-shadow: 0px 10px 20px rgba($color: #000000, $alpha: 0.04);
  padding: 30px 25px;
  @media only screen and (min-width: 641px) {
    width: 48%;
    padding: 30px 40px;
  }
  @media only screen and (min-width: 769px) {
    border-radius: 20px;
    width: 30.5%;
  }

  .photo {
    text-align: center;
  }

  .body {
    h3 {
      font-size: min(4.8vw, 24px);
      font-weight: 700;
      text-align: center;
      margin-top: 15px;
    }

    p {
      font-size: 14px;
      margin-top: 12px;
    }
  }
}

.servicepage__flow__body {
  margin-top: 30px;
}

.servicepage__flow__item {
  display: flex;
  justify-content: center;
  border: none;
  box-shadow: none;
  filter: none;
  border-radius: 0;
  padding: 0;
  padding-bottom: 47px;
  margin-bottom: 20px;
  background: url(../img/foundation/service/dot.svg) center bottom no-repeat;
  background-size: 3px 27px;
  @media only screen and (min-width: 641px) {
    background-position: left 48px bottom;
    padding-bottom: 37px;
    margin-bottom: 5px;
    align-items: flex-start;
  }
  &:last-of-type {
    background: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .photo {
    width: 100px;
    max-width: 100px;
    min-width: 100px;
    //margin-bottom: 0;

    p {
      color: #dd488e;
      font-weight: 700;
      font-size: min(4.267vw, 20px);
      text-align: center;
    }
  }

  .body {
    background: #fff;
    border: 1px solid #d8e3ee;
    border-radius: 14px;
    box-shadow: 0px 10px 20px rgba($color: #000000, $alpha: 0.04);
    padding: 30px 25px;

    h3 {
      @media only screen and (min-width: 641px) {
        margin-bottom: 6px;
      }
    }
  }
}

.page__works,
.page__interview,
.page__blog,
.servicepage__question {
  padding: 40px 20px;
  @media only screen and (min-width: 641px) {
    padding: 40px 30px;
  }
  @media only screen and (min-width: 1024px) {
    padding: 80px 30px;
  }
}

.page__works__list,
.page__interview__list,
.page__blog__list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  @media only screen and (min-width: 641px) {
    margin-bottom: 40px;
    justify-content: space-between;
    max-width: 1180px;
    margin: 40px auto 0;
  }
}

.page__works__item,
.page__interview__item,
.page__blog__item {
  width: 100%;
  margin: 0 0 20px;
  @media only screen and (min-width: 641px) {
    width: calc(33% - 10px);
  }

  a {
    background: #fff;
    border: 1px solid #d8e3ee;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding-bottom: 28px;
    transition: 0.5s all;
    &:hover {
      opacity: 0.7;
      transition: 0.5s all;
    }
  }

  .photo {
    margin-bottom: 26px;
    position: relative;
  }

  .category {
    position: absolute;
    left: 18px;
    bottom: 0;
    -webkit-transform: translateY(50%);
    -ms-transform: translateY(50%);
    transform: translateY(50%);
    height: 28px;
    line-height: 28px;
    background: #106ab5;
    border-radius: 5px;
    color: #fff;
    font-size: 13px;
    padding: 0 10px;
    letter-spacing: 0.03em;
  }

  h3 {
    padding: 0 22px;
    line-height: 15px;
    font-weight: 700;
    line-height: 30px;
    flex-grow: 1;
  }
}

.page__interview__item {
  h3 {
    color: #106ab5;
  }

  p.title {
    padding: 16px 22px 0;
  }
}

.page__blog__item {
  h3 {
    padding: 0 22px;
    line-height: 15px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 20px;
    flex-grow: 1;
  }

  .info {
    padding: 0 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ul.blog_category {
      li {
        padding: 0 10px;
        line-height: 20px;
        font-size: 12px;
        color: #106ab5;
        height: 20px;
        background: #ebf4ff;
        font-weight: 500;
        letter-spacing: 0.03em;
        border-radius: 25px;
      }
    }

    .date {
      color: #888;
      font-size: 12px;
      letter-spacing: 0.03em;
    }
  }
}

.servicepage__question {
  padding-bottom: 0;
}
