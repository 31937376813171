@use "../mixin/index" as *;

.servicepage__operation_sns,
.servicepage__operation_sp,
.servicepage__staff,
.servicepage__sns_case {
  padding: 40px 20px;
  @media only screen and (min-width: 641px) {
    padding: 40px 30px;
  }
  @media only screen and (min-width: 1024px) {
    padding: 80px 30px;
  }
}

.servicepage__operation_sp {
  display: flex;
  flex-wrap: wrap;
  row-gap: 50px;
  max-width: 1000px;
  margin: 0 auto;
  @media only screen and (min-width: 1024px) {
    padding-top: 100px;
    row-gap: 80px;
  }
}

.servicepage__operation_sp__item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .photo {
    text-align: center;
    width: 100%;
    @media only screen and (min-width: 641px) {
      width: 29.4%;
    }
  }
  .body {
    margin-top: 30px;
    @media only screen and (min-width: 641px) {
      width: 60%;
      padding-top: 10%;
    }
    @media only screen and (min-width: 1024px) {
      width: 54%;
    }
    h3 {
      font-size: min(5.333vw, 30px);
      font-weight: 700;
    }

    p {
      margin-top: 20px;
    }
  }

  &:last-of-type {
    @media only screen and (min-width: 641px) {
      .photo {
        order: 2;
      }
      .body {
        order: 1;
      }
    }
  }
}

.servicepage__staff__item {
  background: #fff;
  border-radius: 14px;
  padding: 30px;
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  @media only screen and (min-width: 769px) {
    border-radius: 20px;
    margin-top: 40px;
    justify-content: space-between;
    padding: 6% 10% 6% 11%;
    max-width: 1000px;
    margin: 0 auto;
  }
  @media only screen and (min-width: 1024px) {
    padding: 60px 100px 60px 110px;
  }

  &:last-of-type {
    .servicepage__staff__info {
      justify-content: flex-start;

      @media only screen and (min-width: 1024px) {
        column-gap: 5em;
      }
      p.date {
        width: 100%;
      }
    }
  }
}

.servicepage__staff__photo {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  @media only screen and (min-width: 769px) {
    margin-bottom: 0;
    width: 50%;
    text-align: left;
  }
  @media only screen and (min-width: 1024px) {
    width: 331px;
  }
}

.servicepage__staff__body {
  width: 100%;
  @media only screen and (min-width: 769px) {
    width: calc(50% - 30px);
  }

  @media only screen and (min-width: 1024px) {
    width: calc(100% - 331px);
    padding-left: 90px;
  }

  img {
    display: block;
    margin: 0 auto;
    @media only screen and (min-width: 1024px) {
      width: 314px;
      margin: 0;
    }
  }

  h3 {
    font-size: min(5.333vw, 35px);
    font-weight: 700;
    margin-top: 0.85em;
    letter-spacing: 0.1em;
  }

  p.name-kana {
    color: #106ab5;
    font-size: 14px;
    font-weight: 500;
    margin-top: 0.5em;
    letter-spacing: 0.15em;
  }

  p {
    margin-top: 2em;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.03em;
    @media only screen and (min-width: 1024px) {
      margin-top: 20px;
    }
  }
}

.servicepage__staff__info {
  border-top: 1px solid #d8e3ee;
  margin-top: 30px;
  padding-top: 20px;
  width: 100%;
  @media only screen and (min-width: 769px) {
    display: flex;
    flex-wrap: wrap;
  }
  @media only screen and (min-width: 1024px) {
    margin-top: 40px;
    padding-top: 30px;
    justify-content: space-between;
  }

  p {
    color: #106ab5;
    font-weight: 500;
    @media only screen and (min-width: 769px) {
      padding-right: 2em;
    }
    @media only screen and (min-width: 1024px) {
      padding-right: 0;
      font-size: 18px;
    }
    &:nth-of-type(4) {
      margin-top: 20px;
      @media only screen and (min-width: 769px) {
        width: 100%;
      }
    }
  }

  p.achievement,
  p.date {
    color: #2a3b4c;
  }
}

p.achievement {
  @media only screen and (min-width: 1024px) {
    font-size: 14px;
    width: 80%;
  }
}

p.date {
  margin-top: 20px;
  text-align: right;
  font-size: 13px;
  font-weight: 400;
  @media only screen and (min-width: 769px) {
    width: 100%;
    padding-right: 0;
  }
  @media only screen and (min-width: 1024px) {
    width: auto;
    margin-top: 0;
  }
}

.servicepage__sns_case__item {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  margin-top: 40px;
  @media only screen and (min-width: 641px) {
    align-items: center;
    justify-content: space-between;
    max-width: 1000px;
    margin: 0 auto;
  }
  @media only screen and (min-width: 1024px) {
    margin-top: 80px;
  }
  &:nth-of-type(1) {
    margin-top: 40px;
  }

  .photo {
    width: 100%;
    text-align: center;
    @media only screen and (min-width: 641px) {
      width: 46.2%;
    }
  }

  .body {
    width: 100%;
    @media only screen and (min-width: 641px) {
      width: 45.8%;
    }

    h4 {
      margin-top: 20px;
      font-size: min(4.267vw, 18px);
      font-weight: 500;
      @media only screen and (min-width: 641px) {
        margin-top: 30px;
      }
    }

    p {
      margin-top: 10px;
      font-size: 14px;
      @media only screen and (min-width: 641px) {
        margin-top: 20px;
      }
    }

    .servicepage__sns_case__title {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #d8e3ee;
      padding-bottom: 15px;
      @media only screen and (min-width: 641px) {
        padding-bottom: 25px;
      }

      h3 {
        display: inline-block;
        margin-right: 1.5em;
        font-size: min(4.8vw, 24px);
        font-weight: 700;
      }

      p {
        font-size: min(3.733vw, 19px);
        margin-top: 0;
      }
    }
  }
}
